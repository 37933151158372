import { CityPagination } from './../../domain/model/city_pagination';
import { FetchCityRepository } from './../../repository/fetch_city_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";

export interface FetchCityUseCase {
    (pagination: DataOptions, search: string): Promise<CityPagination>
}

const fetchCityUseCase = (repository: FetchCityRepository): FetchCityUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
          /*   sort: pagination.sortBy.join(','),
            descending: pagination.sortDesc.join(','), */
            sort: "name",
            descending: "",
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchCityUseCase;