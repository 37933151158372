import { DeleteProviderRepository } from '../../data/repository/delete_provider_repository';
import { Provider } from '../model/provider';

export interface DeleteProviderUseCase {
    (id: number): Promise<Provider>
}

const deleteProviderUseCase = (repository: DeleteProviderRepository): DeleteProviderUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteProviderUseCase;