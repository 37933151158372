import { DeleteAccessPlanRepository } from './../../data/repository/delete_access_plan_repository';
import { AccessPlan } from '../model/access_plan';

export interface DeleteAccessPlanUseCase {
    (id: number): Promise<AccessPlan>
}

const deleteAccessPlanUseCase = (repository: DeleteAccessPlanRepository): DeleteAccessPlanUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteAccessPlanUseCase;