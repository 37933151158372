export interface SimpleProduct {
  id: number
  code: string
  providerID: number
  categoryID: number
  name: string
  price: number
  conversion: number
  conversionMeasure: number
  freight: number
  ipi: number
  st: number
  imcsPurchase: number
  totalCost: number
  distributorMargin: number
  resaleMargin: number
  producerMargin: number
  imcsSale: number
  pisCofins: number
  expenses: number
  commission: number
  ipiSale: number
  distributorValue: number
  resaleValue: number
  producerValue: number
  companyID: number
  categoryName: string
}

export const simpleProductDefault = (): SimpleProduct => {
  return {
    id: 0,
    code: "",
    providerID: 0,
    categoryID: 0,
    name: "",
    price: 0,
    conversion: 1,
    conversionMeasure: 1,
    freight: 0,
    ipi: 0,
    st: 0,
    imcsPurchase: 0,
    totalCost: 0,
    distributorMargin: 0,
    resaleMargin: 0,
    producerMargin: 0,
    imcsSale: 0,
    pisCofins: 0,
    expenses: 0,
    commission: 0,
    ipiSale: 0,
    distributorValue: 0,
    resaleValue: 0,
    producerValue: 0,
    companyID: 0,
    categoryName: ""
  }
}