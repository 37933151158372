import Vue from 'vue';
import { getAccountGroupID } from "./jwtAuthToken"

export interface Permission {
    id:             number;
    accountGroupID: ID;
    permissionID:   ID;
    actionID:       ID;
}

export interface ID {
    id:   number;
    name: string;
}

export function hadPermission(permissionID: number, actionID: number): boolean { 
    const accountGroupID = getAccountGroupID()

    if (accountGroupID === 1 ) {
        return true
    }
    
    if (Vue.prototype.permissions) {
        const had = Vue.prototype.permissions.some((permission: Permission) => permission.actionID.id == actionID && permission.permissionID.id == permissionID)
        return had
    }

    return false    
}
