import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { ChangeCategoryRepository } from '../../data/repository/change_category_repository';
import { Category } from '../model/category';

export interface ChangeCategoryUseCase {
    (id: number, data: Category): Promise<Category>
}

const changeCategoryUseCase = (repository: ChangeCategoryRepository): ChangeCategoryUseCase => async (id:number, data: Category) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeCategoryUseCase;

