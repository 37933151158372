export interface Category {
  id: number,
  description: string,
  createdDate: string,
  active: boolean,
  companyID: number
}

export const categoryDefault = (): Category => {
  return {
    id: 0,
    description: "",
    createdDate: "",
    active: true,
    companyID: 0
  }
}
