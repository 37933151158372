<template>
  <v-dialog
    style="z-index: 99999999"
    persistent
    width="500"
    v-model="alert.dialog"
  >
    <v-card
      color="primary"
      class="white--text"
    >
      <v-card-title>
        <v-spacer></v-spacer>
        Aviso!
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="white--text text-center">
        {{ alert.message }}
      </v-card-text>
      <v-card-text>
        <lottie
          class="mt-10"
          :options="alert.lottieAnimation"
          :height="300"
          :width="300"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          block
          outlined
          color="white"
          @click="alert.dialog = false"
        >
          ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Lottie from "./lottie.vue";
export default {
  components: {
    Lottie,
  },
  data: () => ({
    alert: window.alertController,
  }),
};
</script>