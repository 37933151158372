<template>
  <div>
    asdasd
  </div>
</template>

<script>
import { dashboardController } from "@/di/di";
import Lottie from "../../../core/component/lottie.vue";

export default {
  components: {
    Lottie,
  },
  data: (context) => ({
    controller: dashboardController(context),
  }),
  async mounted() {
    await this.controller.mounted();
  },
};
</script>
