export interface SendMailRecoveryUseCase {
    (email: string)
}

const sendMailRecoveryUseCase = (repository: SendMailRecoveryUseCase): SendMailRecoveryUseCase => async (email: string) => {
    try {
        return await repository(email);
    } catch (error) {
        throw error
    }
}

export default sendMailRecoveryUseCase;

