import { CompositeProductPagination } from '../../domain/model/compositeProductPagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchCompositeProductRepository {
    (pagination: Pagination): Promise<CompositeProductPagination>
}

const fetchCompositeProductRepository = (axios: AxiosInstance): FetchCompositeProductRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/composite-product', {
            params: pagination
        })

        const compositeProductPagination: CompositeProductPagination = response.data
        const paginated = compositeProductPagination.items.map((item) => {
            const parts = item.compositeProductParts.map((part, index) => {
                return {
                    ...part,
                    itemOrder: part.itemOrder === null || part.itemOrder === 0 ? index + 1 : part.itemOrder
                }
            })

            return {
                ...item,
                compositeProductParts: parts
            }
        })

        compositeProductPagination.items = paginated
        return compositeProductPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchCompositeProductRepository