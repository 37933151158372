import { DeleteUserRepository } from './../../data/repository/delete_user_repository';
import { User } from '../model/user';

export interface DeleteUserUseCase {
    (id: number): Promise<User>
}

const deleteUserUseCase = (repository: DeleteUserRepository): DeleteUserUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteUserUseCase;