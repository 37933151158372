import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';

import { checkGlobalAccess } from '@/core/domain/model/jwtAuthToken';
import LoginPage from '../../login/view/login_page.vue'
import HomePage from '../../home/view/home_page.vue'
import IntroPage from '../../intro/view/intro_page.vue';
import LoadingPage from '../view/loading_page.vue'

class DefaultController {
    context: any;
    selected: any = LoadingPage;

    private refreshUseCase: RefreshUseCase

    constructor(
        context: any,
        refreshUseCase: RefreshUseCase
    ) {
        this.context = context;
        this.refreshUseCase = refreshUseCase
    }

    async mounted() {
        window.router = this;
        try {
            if (localStorage.getItem('auth') !== null) {
                await this.refreshUseCase()
            } else {
                this.selected = LoginPage
                return
            }
            if (checkGlobalAccess()) {
                this.selected = HomePage
                return
            } else {
                this.selected = IntroPage
                return
            }
        } catch (error) {
            this.selected = LoginPage
        }
        // }

        // if (checkGlobalAccess()) {
        //     this.selected = IntroPage
        //     return
        // } else {
        //     this.selected = LoginPage
        // }


    }

    login() {
        //localStorage.clear()
        localStorage.removeItem('auth')
        this.selected = LoginPage
    }

    home() {
        if (checkGlobalAccess()) {
            this.selected = HomePage
            return
        } else {
            this.selected = IntroPage
            return
        }
    }
}

export default DefaultController