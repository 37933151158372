import { toCurrency } from './../../../../core/domain/usecase/currency';
import { CompositeProduct } from '../model/compositeProduct';
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export interface GenerateCompositeProductReportUseCase {
    (compositeProduct: CompositeProduct)
}


const generateCompositeProductReportUseCase = (): GenerateCompositeProductReportUseCase => async (compositeProduct: CompositeProduct) => {
    try {
        const parts: any =
            compositeProduct.compositeProductParts?.map((item, index) => {
                return [
                    { text: '#' + item.itemOrder.toString() },
                    { text: item.code ? item.code.toString() : '' },
                    { text: item.name ? item.name.toString() : '' },
                    { text: item.totalCost ? toCurrency(item.totalCost) : '', alignment: 'right' },
                    { text: item.conversionFactor === 1 ? 'DIVIDIR' : 'MULTIPLICAR' },
                    { text: item.conversion ? item.conversion.toString() : '' },
                    { text: item.finalCost ? toCurrency(item.finalCost) : '', alignment: 'right' },
                ]
            }) ?? []

        parts.unshift([
            { text: '#' },
            { text: 'Código' },
            { text: 'Produto' },
            { text: 'Custo total', alignment: 'right' },
            { text: 'Conversão' },
            { text: 'Unidade' },
            { text: 'Custo final', alignment: 'right' },
        ])

        var docDefinition = {
            pageMargins: [25, 75, 25, 35],
            header: function (currentPage, pageCount, pageSize) {
                return [
                    {
                        margin: [2, 10, 2, 2],
                        columns: [
                            {
                                margin: [0, 0, 0, 0],
                                text: `${compositeProduct.name}`,
                                fontSize: 15,
                                alignment: 'center',
                                bold: true,
                            },
                        ],
                    },
                    {
                        margin: [0, 0, 0, 0],
                        alignment: 'center',
                        text: `Categoria: ${compositeProduct.categoryName}`,
                        fontSize: 10,
                        bold: true,
                    },
                    {
                        margin: [0, 0, 0, 0],
                        alignment: 'center',
                        text: `Código: ${compositeProduct.code}`,
                        fontSize: 10,
                        bold: true,
                    },
                    // {
                    //     canvas: [{ type: 'line', x1: 0, y1: 10, x2: 5000, y2: 10, lineWidth: 1 }]
                    // }
                ]
            },
            footer: function (currentPage, pageCount) {
                return {
                    margin: 10,
                    columns: [
                        {
                            fontSize: 9,
                            text: [
                                {
                                    text:
                                        '© Visão control. Pagina ' +
                                        currentPage.toString() +
                                        ' de ' +
                                        pageCount + ' | ' + dayjs().format("DD/MM/YYYY - HH:mm"),
                                },
                            ],
                            alignment: 'center',
                        },
                    ],
                }
            },
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        {
                            text: 'Partes do produto',
                            fontSize: 15,
                            bold: true,
                            margin: [0, 0, 0, 10],
                        },
                    ],
                },

                {
                    margin: [0, 0, 0, 10],
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            if (rowIndex == 0) {
                                return null
                            }
                            return rowIndex % 2 == 0 ? null : '#e5e5e5'
                        },
                    },
                    fontSize: 10,
                    table: {
                        headerRows: 1,
                        widths: [20, 50, '*', 100, 100, 50, 50],
                        body: parts,
                    },
                },
                {
                    margin: [0, 0, 0, 20],
                    columns: [
                        {
                            text: `Total de itens no produto composto: ${parts.length - 1}`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'left',
                        },
                        {
                            text: `Custo final: ${toCurrency(compositeProduct.totalCost)}`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'right',
                        },
                    ],
                },
                {
                    margin: [0, 0, 0, 20],
                    columns: [
                        {
                            text: `ICMS: ${compositeProduct.imcsSale}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'left',
                        },
                        {
                            text: `PIS/COFINS: ${compositeProduct.pisCofins}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'center',
                        },
                        {
                            text: `Despesas: ${compositeProduct.expenses}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'center',
                        },
                        {
                            text: `Comissão: ${compositeProduct.commission}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'center',
                        },
                        {
                            text: `IPI: ${compositeProduct.ipiSale}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'right',
                        },
                    ],
                },
                {
                    margin: [0, 0, 0, 20],
                    columns: [
                        {
                            text: `Valor distribuidor: ${toCurrency(compositeProduct.distributorValue)} - ${compositeProduct.distributorMargin}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'left',
                        },
                        {
                            text: `Valor Revenda: ${toCurrency(compositeProduct.resaleValue)} - ${compositeProduct.resaleMargin}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'center',
                        },
                        {
                            text: `Valor produtor: ${toCurrency(compositeProduct.producerValue)} - ${compositeProduct.producerMargin}%`,
                            fontSize: 10,
                            bold: true,
                            alignment: 'right',
                        },
                    ],
                },
            ],
        }
        pdfMake.createPdf(docDefinition).open()
    } catch (error) {
        throw error
    }
}

export { generateCompositeProductReportUseCase }  