<template>
  <div>
    <component v-bind:is="controller.selected"></component>
  </div>
</template>

<script>
import { defaultController } from "@/di/di";
export default {
  data: (context) => ({
    controller: defaultController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>
