import dayjs from "dayjs"

export interface CashFlow {
  id: number
  value: number
  cashFlowTypeID: number
  description: string
  companyID: number
  createdDate: string
}

export const cashFlowDefault = (): CashFlow => {
  return {
    id: 0,
    value: 0,
    cashFlowTypeID: 1,
    description: "",
    companyID: 0,
    createdDate: dayjs().format("YYYY-MM-DD")
  }
}
