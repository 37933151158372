import { defaultPagination, defaultModelPagination } from './../../../core/domain/model/pagination';
import { FetchPreRegistrationUseCase } from './../domain/usecase/fetch_pre_registration_usecase';
import { getAccountGroupID } from '@/core/domain/model/jwtAuthToken';
import { FetchUsersUseCase } from '../domain/usecase/fetch_user_usecase';
import { DeleteUserUseCase } from './../domain/usecase/delete_user_usecase';
import { CreateUserUseCase } from './../domain/usecase/create_user_usecase';
import { ChangeUserUseCase } from './../domain/usecase/change_user_usecase';
import { UserPagination } from './../domain/model/user_pagination';
import { DataOptions } from 'vuetify';
import { headers } from './../const/headers';
import { snackbar } from '@/core/controller/snackbar_controller';
import { CompanyPagination } from '@/module/company/domain/model/company_pagination';
import { FetchCompanyUseCase } from '@/module/company/domain/usecase/fetch_company_usecase';
import { Account, accountDefault } from '@/core/domain/model/account';
class AccountController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCompany: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public userPagination: UserPagination = defaultModelPagination()
  public companyPagination: CompanyPagination = defaultModelPagination()
  public options: DataOptions = defaultPagination({})
  public optionsCompany: DataOptions = defaultPagination({ itemsPerPage: 10, sortBy: ["name"] })
  public preRegistrations: Array<Account> = []

  public user: Account = accountDefault()

  public groupItems: Array<Object> = [
    { id: 2, group: "CLIENTE ADMINISTRADOR" },
    { id: 3, group: "COLABORADOR" },
  ]

  private fetchUsersUseCase: FetchUsersUseCase
  private deleteUserUseCase: DeleteUserUseCase
  private createUserUseCase: CreateUserUseCase
  private changeUserUseCase: ChangeUserUseCase
  private fetchCompanyUseCase: FetchCompanyUseCase
  private fetchPreRegistrationUseCase: FetchPreRegistrationUseCase

  constructor(
    context: any,
    fetchUsersUseCase: FetchUsersUseCase,
    deleteUserUseCase: DeleteUserUseCase,
    createUserUseCase: CreateUserUseCase,
    changeUserUseCase: ChangeUserUseCase,
    fetchCompanyUseCase: FetchCompanyUseCase,
    fetchPreRegistrationUseCase: FetchPreRegistrationUseCase
  ) {
    this.context = context
    this.fetchUsersUseCase = fetchUsersUseCase
    this.deleteUserUseCase = deleteUserUseCase
    this.createUserUseCase = createUserUseCase
    this.changeUserUseCase = changeUserUseCase
    this.fetchCompanyUseCase = fetchCompanyUseCase
    this.fetchPreRegistrationUseCase = fetchPreRegistrationUseCase
  }

  open() {
    if (getAccountGroupID() == 1) {
      this.groupItems.unshift({ id: 1, group: "ADMINISTRADOR" },)
    }
    this.flagOption = "create"
    //this.user = { id: 0, active: true, email: "", login: "", name: "", password: "", accountGroupID: 0, companyID: 0, companyName: "" }
    this.paginateCompany()
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.userPagination = await this.fetchUsersUseCase(this.options, this.search)
      this.preRegistrations = await this.fetchPreRegistrationUseCase()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  async paginateCompany() {
    if (this.searchCompany != null) {
      try {
        this.companyPagination = await this.fetchCompanyUseCase(this.optionsCompany, this.searchCompany)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchOptionsCompany() {
    this.paginateCompany()
  }

  watchSearchCompany() {
    this.paginateCompany()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createUserUseCase(this.user)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeUserUseCase(this.user.id, this.user)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Account) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red' })
      if (confirm) {
        await this.deleteUserUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Account) {
    this.dialog = true
    this.flagOption = "change"
    this.searchCompany = item.companyName
    this.paginateCompany()
    this.user = { ...item }
  }

  async updatePreRegistration(user: Account) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente liberar o acesso para o usuário?" })
      if (confirm) {
        user.preRegistration = false
        await this.changeUserUseCase(user.id, user)
        this.paginate()
        snackbar.show({ message: "Usuário liberado para usar a plataforma!", color: 'blue', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }
}

export default AccountController