import { CompositeProduct } from '../../domain/model/compositeProduct';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteCompositeProductRepository {
    (id: number): Promise<CompositeProduct>
}

const deleteCompositeProductRepository = (axios: AxiosInstance): DeleteCompositeProductRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/composite-product/${id}`)

        const compositeProduct: CompositeProduct = response.data
        return compositeProduct
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteCompositeProductRepository