export const headers = [
  {
    text: 'Código',
    align: 'start',
    sortable: true,
    value: 'code',
  },
  {
    text: 'Produto',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Categoria',
    align: 'start',
    sortable: true,
    value: 'categoryName',
  },
  {
    text: 'Custo total',
    align: 'end',
    sortable: true,
    value: 'totalCost',
  },

  {
    text: 'Distribuidor',
    align: 'end',
    sortable: true,
    value: 'distributorValue',
    width: "170px"
  },
  {
    text: 'Revenda',
    align: 'end',
    sortable: true,
    value: 'resaleValue',
    width: "170px"
  },
  {
    text: 'Produtor',
    align: 'end',
    sortable: true,
    value: 'producerValue',
    width: "170px"
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: "220px"
  }
]


export const headersParts = [
  {
    text: 'Ordem',
    align: 'start',
    sortable: false,
    value: 'itemOrder',
  },
  {
    text: 'Código',
    align: 'start',
    sortable: true,
    value: 'code',
  },
  {
    text: 'Produto',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Custo total',
    align: 'end',
    sortable: true,
    value: 'totalCost',
  },
  {
    text: 'Conversão',
    align: 'start',
    sortable: true,
    value: 'conversionFactor',
    width: 200
  },
  {
    text: 'Unidade',
    align: 'start',
    sortable: true,
    value: 'conversion',
    width: 120
  },
  {
    text: 'Custo final',
    align: 'end',
    sortable: true,
    value: 'finalCost',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '110px'
  }
]

export const headersInventory = [
  {
    text: 'Data da movimentação',
    align: 'start',
    sortable: false,
    value: 'createdDate',
  },
  {
    text: 'Descrição',
    align: 'start',
    sortable: false,
    value: 'description',
  },
  {
    text: 'Quantidade',
    align: 'end',
    sortable: false,
    value: 'amount',
  },
]

