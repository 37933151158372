<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="73vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.optionsProvider"
      :server-items-length="controller.providerPagination.total"
      :items="controller.providerPagination.items"
      :loading="controller.loading"
      :mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-start">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">Fornecedores</v-toolbar-title>
          <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          <v-text-field
            class="px-2"
            outlined
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção do fornecedor
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-select
                        outlined
                        dense
                        label="Tipo de cadastro"
                        :items="controller.providersType"
                        v-model="controller.provider.providerTypeID"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-col>
                    <v-col
                      lg="8"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Apelido"
                        v-model="controller.provider.surname"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="6"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 1"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Razão social"
                        v-model="controller.provider.corporateName"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="6"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 1"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Nome fantasia"
                        v-model="controller.provider.fantasyName"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="6"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 2"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Nome"
                        v-model="controller.provider.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="3"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        :label="controller.provider.providerTypeID == 1 ? 'CNPJ' : 'CPF'"
                        v-model="controller.provider.cpfCnpj"
                        v-mask=" controller.provider.providerTypeID == 1 ? '##.###.###/####-##' : '###.###.###-##'"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="3"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 2"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="RG"
                        v-model="controller.provider.rg"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="3"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 1"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Inscrição estadual"
                        v-model="controller.provider.stateRegistration"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="3"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 1"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Inscrição municipal"
                        v-model="controller.provider.municipalRegistration"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="3"
                      cols="12"
                      class="py-0"
                      v-if="controller.provider.providerTypeID == 1"
                    >
                      <v-checkbox
                        class="pt-0 mt-2"
                        label="Contribuinte ICMS"
                        v-model="controller.provider.taxpayerIcms"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mb-3"
                  >
                    <v-col>
                      <v-divider class="mt-4" />
                    </v-col>
                    <v-col class="text-center"> Informações de contato </v-col>
                    <v-col>
                      <v-divider class="mt-3" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Telefone"
                        v-model="controller.provider.phone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="E-mail"
                        v-model="controller.provider.email"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Responsável"
                        v-model="controller.provider.nameOfResponsile"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mb-3"
                  >
                    <v-col>
                      <v-divider class="mt-4" />
                    </v-col>
                    <v-col class="text-center"> Endereço </v-col>
                    <v-col>
                      <v-divider class="mt-3" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Endereço"
                        v-model="controller.provider.addressStreat"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Número"
                        v-model="controller.provider.addressNumber"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Complemento"
                        v-model="controller.provider.addressComplement"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Bairro"
                        v-model="controller.provider.addressDistrict"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-autocomplete
                        outlined
                        :items="controller.cityPagination.items"
                        item-text="name"
                        item-value="id"
                        dense
                        label="Cidade"
                        v-model="controller.provider.cityID"
                        :search-input.sync="controller.searchCity"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="CEP"
                        v-model="controller.provider.cep"
                        v-mask="'#####-###'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { providerController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: providerController(context),
  }),
  watch: {
    "controller.optionsProvider"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.searchCity": _.debounce(function () {
      this.controller.watchSearchCity();
    }, 500),
  },
};
</script>