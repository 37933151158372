export const headers = [
  {
    text: 'Ativo',
    align: 'start',
    sortable: true,
    value: 'active',
    custom: true
  },
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Nome Fantasia',
    align: 'start',
    sortable: true,
    value: 'comporateName',
  },
  {
    text: 'CPF/CNPJ',
    align: 'start',
    sortable: true,
    value: 'cpfCnpj',
  },
  {
    text: 'Telefone',
    align: 'start',
    sortable: true,
    value: 'phone',
  },

  {
    text: 'Endereço',
    align: 'start',
    sortable: true,
    value: 'address',
  },
  {
    text: 'Cidade',
    align: 'start',
    sortable: true,
    value: 'cityName',
  },
  {
    text: 'Plano de acesso',
    align: 'start',
    sortable: true,
    value: 'accessPlanName',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
  }
]
