import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Provider } from '../../domain/model/provider';

export interface CreateProviderRepository {
  (data: Provider): Promise<Provider>
}

const createProviderRepository = (axios: AxiosInstance): CreateProviderRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/provider`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createProviderRepository

