import { FetchCityUseCase } from '@/module/city/domain/usecase/fetch_city_usecase';
import { FetchAccessPlanUseCase } from '../domain/usecase/fetch_access_plan_usecase';
import { DeleteAccessPlanUseCase } from '../domain/usecase/delete_access_plan_usecase';
import { CreateAccessPlanUseCase } from '../domain/usecase/create_access_plan_usecase';
import { ChangeAccessPlanUseCase } from '../domain/usecase/change_access_plan_usecase';
import { AccessPlanPagination } from '../domain/model/access_plan_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { AccessPlan, accessPlanDefault } from '../domain/model/access_plan';
import { snackbar } from '@/core/controller/snackbar_controller';

class AccessPlanController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public loading: boolean = false
  public accessPlanPagination: AccessPlanPagination = {
    total: 0,
    items: []
  }
  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public accessPlan: AccessPlan = accessPlanDefault()

  private fetchAccessPlanUseCase: FetchAccessPlanUseCase
  private deleteAccessPlanUseCase: DeleteAccessPlanUseCase
  private createAccessPlanUseCase: CreateAccessPlanUseCase
  private changeAccessPlanUseCase: ChangeAccessPlanUseCase

  constructor(
    context: any,
    fetchAccessPlanUseCase: FetchAccessPlanUseCase,
    deleteAccessPlanUseCase: DeleteAccessPlanUseCase,
    createAccessPlanUseCase: CreateAccessPlanUseCase,
    changeAccessPlanUseCase: ChangeAccessPlanUseCase,
  ) {
    this.context = context
    this.fetchAccessPlanUseCase = fetchAccessPlanUseCase
    this.deleteAccessPlanUseCase = deleteAccessPlanUseCase
    this.createAccessPlanUseCase = createAccessPlanUseCase
    this.changeAccessPlanUseCase = changeAccessPlanUseCase
  }

  open() {
    this.accessPlan = accessPlanDefault();
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.accessPlanPagination = await this.fetchAccessPlanUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.accessPlan.id == 0) {
          await this.createAccessPlanUseCase(this.accessPlan)
          this.paginate()
          snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
        } else {
          await this.changeAccessPlanUseCase(this.accessPlan.id, this.accessPlan)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: AccessPlan) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteAccessPlanUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: AccessPlan) {
    this.dialog = true
    this.accessPlan = { ...item }
  }
}

export default AccessPlanController