import { FetchInventoryByCompositeProductIDRepository } from '../../data/repository/fetch_inventory_by_composite_product_id_repository';
import { InventoryPagination } from '../model/inventoryPagination';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";

export interface FetchInventoryByCompositeProductIDUseCase {
    (id: number, pagination: DataOptions, search: string): Promise<InventoryPagination>
}

const fetchInventoryByCompositeProductIDUseCase = (repository: FetchInventoryByCompositeProductIDRepository): FetchInventoryByCompositeProductIDUseCase => async (id: number, pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search ?? ""
        }
        return await repository(id, payload);
    } catch (error) {
        throw error
    }
}

export default fetchInventoryByCompositeProductIDUseCase;