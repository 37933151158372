import { CompositeProductPagination } from '../model/compositeProductPagination';
import { FetchCompositeProductRepository } from '../../data/repository/fetch_composite_product_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";

export interface FetchCompositeProductUseCase {
    (pagination: DataOptions, search: string): Promise<CompositeProductPagination>
}

const fetchCompositeProductUseCase = (repository: FetchCompositeProductRepository): FetchCompositeProductUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search ?? ""
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchCompositeProductUseCase;