import { UserPagination } from './../../domain/model/user_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchUserRepository {
    (pagination: Pagination): Promise<UserPagination>
}

const fetchUserRepository = (axios: AxiosInstance): FetchUserRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/account', {
            params: pagination,
        })

        const userPagination: UserPagination = response.data
        return userPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchUserRepository