import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { GetCompanyByIDRepository } from './../../data/repository/get_company_by_id_repository';
import { Company } from './../../../company/domain/model/company';


export interface GetCompanyByIDUseCase {
    (): Promise<Company>
}

const getCompanyByIDUseCase = (repository: GetCompanyByIDRepository): GetCompanyByIDUseCase => async () => {
    try {
        const companyID : number | null = getCompanyID()
        return await repository(companyID!);
    } catch (error) {
        throw error
    }
}

export default getCompanyByIDUseCase;

