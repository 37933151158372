class DashboardController {
  constructor( 
    private context: any
  ) { }

  async mounted() {
    // window.addEventListener("resize", () => {
    //   this.pieChart?.resize()
    //   this.powerChart?.resize()
    //   this.rulerChart?.resize()
    // });

    // try {
    //   this.company = await this.getCompanyByIDUseCase()
    //   this.rulers = await this.fetchRulerUseCase(this.pagination, "", 1)
    //   this.ruler = this.rulers.items?.[0] ?? null
    //   this.bombMeasurement = await this.fetchCompanyMeasurementUseCase()

    //   this.placePagination = await this.fetchPlaceUseCase(this.options, this.search)
    //   this.selectedPlace = this.placePagination.items[0]
    //   if (this.selectedPlace != null) {
    //     await this.changePlace(this.selectedPlace)
    //   }

    //   this.center = {
    //     lat: parseFloat(this.company.latitude),
    //     lng: parseFloat(this.company.longitude)
    //   }

    //   await new Promise(resolver => setTimeout(resolver, 500))
    //   if (this.bombMeasurement.length > 0) {
    //     this.createPieChart()
    //   }
    // } catch (error: any) {
    //   snackbar.show({ message: error.toString() })
    // }
  }
}

export default DashboardController