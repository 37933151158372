import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { User } from '../../domain/model/user';

export interface ChangeUserRepository {
  (id: number, data: object): Promise<User>
}

const changeUserRepository = (axios: AxiosInstance): ChangeUserRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/account/${id}`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeUserRepository

