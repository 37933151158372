import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Company } from '../../domain/model/company';

export interface ChangeCompanyRepository {
  (id: number, data: object): Promise<Company>
}

const changeCompanyRepository = (axios: AxiosInstance): ChangeCompanyRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/company/${id}`,
      data
    )

    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeCompanyRepository

