import { CreateUserRepository } from './../../data/repository/create_user_repository';
import { User } from '../model/user';

export interface CreateUserUseCase {
    (data: object): Promise<User>
}

const createUserUseCase = (repository: CreateUserRepository): CreateUserUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createUserUseCase;

