import { Account } from './account';
import jwtDecode from "jwt-decode";
import Vue from 'vue';
export interface JwtAuthToken {
    token: string;
    refresh: string;
    type: string;
}

export function getJwtAuthToken(): JwtAuthToken {
    const auth = localStorage.getItem('auth')    
    const jwtAuthToken: JwtAuthToken = JSON.parse(auth ?? '{}')

    return jwtAuthToken
}


export function getAccount(): Account {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token
}

export function getAccountFromToken(token: string): Account {
    const decoded: any = jwtDecode(token)
    const newToken: Account = decoded.account

    return newToken
}

export function getCompanyID(): number | null {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.companyID
}

export function checkGlobalAccess(): boolean | null {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return !token.preRegistration && token.companyID !== null && token.accessPlanID !== null
}

export function getAccessPlan(): number | null {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.accessPlanID
}


export function isPreRegistration(): boolean | null {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.preRegistration
}

export function getAccountGroupID(): number {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.accountGroupID
}


export function getAccountName(): string {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account
    

    return token.name
}

export function isAdmin(): boolean {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.accountGroupID == 1
}

export function isClientAdmin(): boolean {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.accountGroupID == 2
}

export function expired(token: string) {
    try {
        const currentTime = new Date().getTime() / 1000
        const decoded: any = jwtDecode(token)

        if (currentTime > decoded.exp) {
            return true
        }

        return false
    } catch {
        return true
    }
}

Vue.prototype.getAccountGroupID = getAccountGroupID