import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { ChangeProviderRepository } from '../../data/repository/change_provider_repository';
import { Provider } from '../model/provider';

export interface ChangeProviderUseCase {
    (id: number, data: Provider): Promise<Provider>
}

const changeProviderUseCase = (repository: ChangeProviderRepository): ChangeProviderUseCase => async (id:number, data: Provider) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeProviderUseCase;

