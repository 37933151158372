import { Permission } from './../model/permission';
import { FetchAccounPermissionsRepository } from "@/core/data/repository/fetch_account_permissions_repository";

export interface FetchAccountPermissionsUseCase {
    (id: number): Promise<Array<Permission>>
}

const fetchAccountPermissionsUseCase = (repository: FetchAccounPermissionsRepository): FetchAccountPermissionsUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default fetchAccountPermissionsUseCase;