import Handler from '@/core/domain/usecase/handler';
import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { AxiosInstance, AxiosResponse } from 'axios';
export interface LoginRepository {
    (username: string, password: string): Promise<JwtAuthToken>
}

const loginRepostory = (axios: AxiosInstance): LoginRepository => async (username: string, password: string) => {
    try {
        const response: AxiosResponse = await axios.post('/auth/session', {
            "login": username,
            "password": password
        })

        const jwtAuthToken: JwtAuthToken = response.data
        return jwtAuthToken
    } catch (err: any) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default loginRepostory