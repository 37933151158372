import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { CompositeProduct } from '../../domain/model/compositeProduct';

export interface CreateCompositeProductRepository {
  (data: CompositeProduct): Promise<CompositeProduct>
}

const createCompositeProductRepository = (axios: AxiosInstance): CreateCompositeProductRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/composite-product`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createCompositeProductRepository

