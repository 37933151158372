<template>
  <v-app style="overflow: hidden">
    <div
      v-if="!$vuetify.breakpoint.smAndDown"
      class="circle"
      :style="{'width': $vuetify.breakpoint.mdAndDown ? '70%' : '60%'}"
    ></div>
    <v-row
      :align="!$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
      class="fill-height pa-0 ma-0"
    >
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
        cols="12"
        lg="8"
        md="9"
        style="z-index: 1"
      >
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            lg="8"
            cols="12"
            :class="{'pr-15': $vuetify.breakpoint.mdAndUp}"
          >
            <v-icon
              :class="{'mb-6': $vuetify.breakpoint.mdAndDown}"
              :color="!$vuetify.breakpoint.smAndDown ? 'white' : 'black'"
              @click="controller.returnOptions()"
            >mdi-arrow-left</v-icon>
            <div>
              <v-img
                v-if="!$vuetify.breakpoint.smAndDown"
                :src="!$vuetify.breakpoint.smAndDown ? '/logo_branco.png' : '/NVL2.png'"
                max-height="200"
                max-width="300"
                contain
                class="mb-16"
              ></v-img>
            </div>
            <div
              class="font-weight-bold text-lg-h3 text-md-h4 text-sm-h4 text-xs-h4 text-center text-md-left"
              :class="{'white--text': !$vuetify.breakpoint.smAndDown}"
            >
              <span id="quest1"></span>
            </div>
            <div
              style="opacity: 0.7"
              class="font-weight-bold text-lg-h5 text-md-h6 text-sm-h6 text-xs-h6 text-center text-md-left"
              :class="{'white--text': !$vuetify.breakpoint.smAndDown}"
            >
              <span id="desc1"></span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        offset-lg="1"
        offset-md="1"
        lg="3"
        md="3"
        cols="12"
      >
        <!-- <v-carousel
          height="550"
          hide-delimiters
          v-if="controller.refreshButton && controller.option == 3 && isClientAdmin()"
        >
          <v-carousel-item
            class="px-10"
            v-for="(plan, index) in controller.accessPlanPagination.items"
            :key="index"
          > -->
        <v-card
          elevation="0"
          v-for="(plan, index) in controller.accessPlanPagination.items"
          :key="index"
          v-show="controller.refreshButton && controller.option == 3 && isClientAdmin()"
        >
          <v-card-title class="headline" :class="{'text-center': $vuetify.breakpoint.smAndDown}">{{ plan.name }}</v-card-title>
          <v-card-text class="display-1 primary--text" :class="{'text-center': $vuetify.breakpoint.smAndDown}">
            <strong>{{ plan.price === 0 ? 'Gratuito' : plan.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) + ' / Mês' }}</strong>
          </v-card-text>
          <v-card-text :class="{'text-center': $vuetify.breakpoint.smAndDown}">
            {{ plan.description }}
          </v-card-text>
          <v-card-text >
            <v-list dense>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ plan.maxEmployeeRegistration }} funcionários</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ plan.maxPlaceRegistration }} levantes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ plan.maxPumpRegistration }} bombas</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ plan.maxRulerRegistration }} réguas</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ plan.maxPumpMesurement }} medições por bomba por dia</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ plan.maxRulerMesurement }} medições por régua por dia</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn
              @click="controller.updateCompany(plan)"
              x-large
              outlined
              color="primary"
              block
            >escolher plano</v-btn>
          </v-card-actions>
        </v-card>
        <!-- </v-carousel-item>
        </v-carousel> -->

        <v-card
          @click="controller.refreshStatus()"
          v-if="controller.refreshButton && controller.option == 1"
          elevation="0"
          class="my-4"
          style="border-radius: 20px; background: linear-gradient(345deg,rgba(0, 138, 252, 1) 40%,rgba(81, 169, 241, 1) 100%);"
          height="150"
        >
          <v-card-text class="white--text font-weight-bold headline d-flex justify-center align-center fill-height">
            <v-icon
              x-large
              left
              color="white"
            >mdi-refresh</v-icon>Atualizar status
          </v-card-text>
        </v-card>
        <div
          id="style-7"
          style="overflow-y: auto; max-height: 75vh"
          v-if="controller.refreshButton && controller.option == 2"
          class="px-2"
        >
          <v-form ref="formCompanyRegistration">
            <v-text-field
              filled
              dense
              color="primary"
              label="CPF/CNPJ"
              v-model="controller.company.cpfCnpj"
              @blur="controller.getCnpj()"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
            >
            </v-text-field>
            <v-text-field
              filled
              dense
              color="primary"
              label="Nome da empresa"
              v-model="controller.company.name"
              :rules="[(v) => !!v || 'Preencha o campo']"
            >
            </v-text-field>
            <v-text-field
              filled
              dense
              color="primary"
              label="Nome fantasia"
              v-model="controller.company.comporateName"
              :rules="[(v) => !!v || 'Preencha o campo']"
            >
            </v-text-field>
            <v-text-field
              filled
              dense
              color="primary"
              label="Telefone"
              v-model="controller.company.phone"
              :rules="[(v) => !!v || 'Preencha o campo']"
              v-mask="['(##) ####-####', '(##) #####-####']"
            >
            </v-text-field>
            <v-text-field
              filled
              dense
              color="primary"
              label="Endereço"
              v-model="controller.company.address"
              :rules="[(v) => !!v || 'Preencha o campo']"
            >
            </v-text-field>
            <v-autocomplete
              filled
              dense
              color="primary"
              label="Cidade"
              v-model="controller.company.cityID"
              :search-input.sync="controller.searchCity"
              :items="controller.cityPagination.items"
              item-text="name"
              item-value="id"
              :rules="[(v) => !!v || 'Preencha o campo']"
            >
            </v-autocomplete>
            <!-- <v-text-field
              filled
              dense
              readonly
              color="primary"
              label="Latitude"
              v-model="controller.company.latitude"
              :rules="[(v) => !!v || 'Preencha o campo']"
            >
            </v-text-field>
            <v-text-field
              filled
              dense
              readonly
              color="primary"
              label="Longitude"
              v-model="controller.company.longitude"
              :rules="[(v) => !!v || 'Preencha o campo']"
            >
            </v-text-field> -->
            <form-maps
              :center="controller.center"
              :zoom="controller.zoom"
              @get-location="controller.getLocation($event)"
            ></form-maps>

          </v-form>

        </div>
        <div
          v-if="controller.refreshButton && controller.option == 2"
          class="px-2"
        >
          <v-btn
            @click="controller.createCompany()"
            class="mt-5"
            color="primary"
            style="background: linear-gradient(345deg,rgba(0, 138, 252, 1) 40%,rgba(81, 169, 241, 1) 100%);"
            block
            height="50"
            :loading="controller.loading"
          >cadastrar</v-btn>
        </div>

      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        offset-md="1"
        style="z-index: 1"
      >
        <v-row
          justify="end"
          align="end"
        >
          <v-col
            lg="8"
            class="pr-15 "
          >

            <v-img
              src="/logo_branco.png"
              max-height="200"
              max-width="300"
              contain
              class="mb-16"
            ></v-img>
            <div class="white--text font-weight-bold display-2">
              <span id="quest1"></span>
            </div>
            <div
              style="opacity: 0.7"
              class="white--text font-weight-bold headline"
            >
              <span id="desc1"></span>
            </div>
            <v-btn
              @click="controller.logOut()"
              class="mt-8"
              outlined
              color="white"
              v-if="controller.refreshButton"
            >
              <v-icon left>mdi-exit-to-app</v-icon> sair
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { introController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: introController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.searchCity": _.debounce(function () {
      this.controller.watchSearchCity();
    }, 500),
  },
};
</script>

<style scoped>
.circle {
  width: 60%;
  height: 120vh;
  right: -100px;
  background: linear-gradient(
    345deg,
    rgba(0, 138, 252, 1) 10%,
    rgba(81, 169, 241, 1) 100%
  );
  -moz-border-radius: 30% 0 0 30%;
  -webkit-border-radius: 30% 0 0 30%;
  border-radius: 30% 0 0 30%;
  position: absolute;
  transform: rotate(-10deg);
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(0, 138, 252, 1)),
    color-stop(0.72, rgb(81, 169, 241, 1))
  );
}
</style>
