export const headers = [
  {
    text: 'Data da movimentação',
    align: 'start',
    sortable: true,
    value: 'createdDate',
    width: '110px'
  },
  {
    text: 'Descrição',
    align: 'start',
    sortable: false,
    value: 'description',
    width: '80%'
  },
  {
    text: 'Tipo',
    align: 'center',
    sortable: false,
    value: 'cashFlowTypeName',
  },
  {
    text: 'Valor',
    align: 'end',
    sortable: true,
    value: 'value',
  },
  // {
  //   text: 'Ações',
  //   value: 'actions',
  //   align: 'right',
  //   sortable: false,
  //   width: '110px'
  // }
]
