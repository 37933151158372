export interface Provider {
  id: number
  providerTypeID: number
  surname: string
  corporateName: string
  fantasyName: string
  cpfCnpj: string
  stateRegistration: string
  municipalRegistration: string
  taxpayerIcms: false
  rg: string
  phone: string
  email: string
  nameOfResponsile: string
  addressStreat: string
  addressNumber: string
  addressComplement: string
  addressDistrict: string
  cityID: number
  cep: string
  companyID: number
  name: string
}

export const providerDefault = (): Provider => {
  return {
    id: 0,
    providerTypeID: 1,
    surname: "",
    corporateName: "",
    fantasyName: "",
    cpfCnpj: "",
    stateRegistration: "",
    municipalRegistration: "",
    taxpayerIcms: false,
    rg: "",
    phone: "",
    email: "",
    nameOfResponsile: "",
    addressStreat: "",
    addressNumber: "",
    addressComplement: "",
    addressDistrict: "",
    cityID: 0,
    cep: "",
    companyID: 0,
    name: ""
  }
}
