import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { snackbar } from '@/core/controller/snackbar_controller';
import { Category, categoryDefault } from '../domain/model/category';
import { CategoryPagination } from '../domain/model/category_pagination';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';
import { FetchCategoryUseCase } from '../domain/usecase/fetch_category_usecase';
import { DeleteCategoryUseCase } from '../domain/usecase/delete_category_usecase';
import { ChangeCategoryUseCase } from '../domain/usecase/change_category_usecase';
import { CreateCategoryUseCase } from '../domain/usecase/create_category_usecase';

class CategoryController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public loading: boolean = false
  public categoryPagination: CategoryPagination = defaultModelPagination()
  public optionsCategory: DataOptions = defaultPagination({})
  public category: Category = categoryDefault()

  constructor(
    private context: any,
    private fetchCategoryUseCase: FetchCategoryUseCase,
    private deleteCategoryUseCase: DeleteCategoryUseCase,
    private createCategoryUseCase: CreateCategoryUseCase,
    private changeCategoryUseCase: ChangeCategoryUseCase,
  ) { }


  close() {
    this.category = categoryDefault()
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.categoryPagination = await this.fetchCategoryUseCase(this.optionsCategory, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.category.id != 0) {
          await this.changeCategoryUseCase(this.category.id, this.category)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })


          this.dialog = false
          this.context.$refs.crud.resetValidation()
          return
        }

        await this.createCategoryUseCase(this.category)
        this.paginate()
        snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })

        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: Category) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteCategoryUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Category) {
    this.category = { ...item }
    this.dialog = true
  }
}

export default CategoryController