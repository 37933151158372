import fetchInventoryByCompositeProductIDRepository, { FetchInventoryByCompositeProductIDRepository } from './../module/inventory/data/repository/fetch_inventory_by_composite_product_id_repository';
import fetchPreRegistrationRepository from './../module/user/data/repository/fetch_pre_registration_repository';
import DefaultController from "@/module/default/controller/default_controller";
import LoginController from '@/module/login/controller/login_controller';
import HomeController from '@/module/home/controller/home_controller';
import UserController from '@/module/user/controller/user_controller';
import CompanyController from '@/module/company/controller/company_controller';
import loginRepostory from "@/core/data/repository/login_repository";
import loginUseCase from "@/core/domain/usecase/login_usecase";

//User
import fetchUserRepository from "@/module/user/data/repository/fetch_user_repository";
import fetchUserUseCase from "@/module/user/domain/usecase/fetch_user_usecase";
import deleteUserRepository from "@/module/user/data/repository/delete_user_repository";
import deleteUserUseCase from "@/module/user/domain/usecase/delete_user_usecase";
import createUserRepository from "@/module/user/data/repository/create_user_repository";
import createUserUseCase from "@/module/user/domain/usecase/create_user_usecase";
import changeUserRepository from "@/module/user/data/repository/change_user_repository";
import changeUserUseCase from "@/module/user/domain/usecase/change_user_usecase";

//City
import fetchCityRepository from "@/module/city/repository/fetch_city_repository";
import fetchCityUseCase from "@/module/city/domain/usecase/fetch_city_usecase";

//Company
import fetchCompanyRepository from "@/module/company/data/repository/fetch_company_repository";
import fetchCompanyUseCase from "@/module/company/domain/usecase/fetch_company_usecase";
import deleteCompanyRepository from "@/module/company/data/repository/delete_company_repository";
import deleteCompanyUseCase from "@/module/company/domain/usecase/delete_company_usecase";
import createCompanyRepository from "@/module/company/data/repository/create_company_repository";
import createCompanyUseCase from "@/module/company/domain/usecase/create_company_usecase";
import changeCompanyRepository from "@/module/company/data/repository/change_company_repository";
import changeCompanyUseCase from "@/module/company/domain/usecase/change_company_usecase";
import LogsController from "@/module/logs/controller/logs_controller";
import fetchRulerLogsRepository from "@/module/logs/data/repository/fetch_logs_repository";
import fetchRulerLogsUseCase from "@/module/logs/domain/usecase/fetch_logs_usecase";
import fetchCnpjRepository from "@/module/company/data/repository/fetch_cnpj_repository";
import fetchCnpjUseCase from "@/module/company/domain/usecase/fetch_cnpj_usecase";

//Home
import fetchAccounPermissionsRepository from "@/core/data/repository/fetch_account_permissions_repository";
import fetchAccountPermissionsUseCase from "@/core/domain/usecase/fetch_account_permissions_usecase";
import DashboardController from "@/module/dashboard/controller/dashboard_controller";
import getCompanyByIDRepository from "@/module/dashboard/data/repository/get_company_by_id_repository";
import getCompanyByIDUseCase from "@/module/dashboard/domain/usecase/get_company_by_id_usecase";


import fetchAccessPlanRepository from '@/module/accessPlan/data/repository/fetch_access_plan_repository';
import fetchAccessPlanUseCase from '@/module/accessPlan/domain/usecase/fetch_access_plan_usecase';
import deleteAccessPlanRepository from '@/module/accessPlan/data/repository/delete_access_plan_repository';
import deleteAccessPlanUseCase from '@/module/accessPlan/domain/usecase/delete_access_plan_usecase';
import createAccessPlanRepository from '@/module/accessPlan/data/repository/create_access_plan_repository';
import createAccessPlanUseCase from '@/module/accessPlan/domain/usecase/create_access_plan_usecase';
import changeAccessPlanRepository from '@/module/accessPlan/data/repository/change_access_plan_repository';
import changeAccessPlanUseCase from '@/module/accessPlan/domain/usecase/change_access_plan_usecase';
import AccessPlanController from '@/module/accessPlan/controller/access_plan_controller';
import NewAccountController from '@/module/newAccount/controller/new_account_controller';
import fetchPublicCompanyRepository from '@/module/company/data/repository/fetch_public_company_repository';
import fetchPublicCompanyUseCase from '@/module/company/domain/usecase/fetch_public_company_usecase';
import IntroController from '@/module/intro/controller/intro_controller';
import fetchPreRegistrationUseCase from '@/module/user/domain/usecase/fetch_pre_registration_usecase';
import PasswordRecoveryController from '@/module/passwordRecovery/controller/password_recovery_controller';
import sendMailRecoveryRepository from '@/module/passwordRecovery/data/repository/send_mail_recovery_repository';
import sendMailRecoveryUseCase from '@/module/passwordRecovery/domain/usecase/send_mail_recovery_usecase';
import { axosInstance, refreshUseCaseImpl } from './axios_instance_di';
import fetchSimpleProductRepository from '@/module/simpleProduct/data/repository/fetch_simple_product_repository';
import fetchSimpleProductUseCase from '@/module/simpleProduct/domain/usecase/fetch_simple_product_usecase';
import deleteSimpleProductRepository from '@/module/simpleProduct/data/repository/delete_simple_producy_repository';
import deleteSimpleProductUseCase from '@/module/simpleProduct/domain/usecase/delete_simple_product_usecase';
import createSimpleProductRepository from '@/module/simpleProduct/data/repository/create_simple_product_repository';
import changeSimpleProductRepository from '@/module/simpleProduct/data/repository/change_simple_product_repository';
import changeSimpleProductUseCase from '@/module/simpleProduct/domain/usecase/change_simple_product_usecase';
import createSimpleProductUseCase from '@/module/simpleProduct/domain/usecase/create_simple_product_usecase';
import SimpleProductController from '@/module/simpleProduct/controller/simple_product_controller';
import fetchCategoryRepository from '@/module/category/data/repository/fetch_category_repository';
import fetchCategoryUseCase from '@/module/category/domain/usecase/fetch_category_usecase';
import deleteCategoryRepository from '@/module/category/data/repository/delete_category_repository';
import deleteCategoryUseCase from '@/module/category/domain/usecase/delete_category_usecase';
import createCategoryRepository from '@/module/category/data/repository/create_category_repository';
import createCategoryUseCase from '@/module/category/domain/usecase/create_category_usecase';
import changeCategoryRepository from '@/module/category/data/repository/change_category_repository';
import changeCategoryUseCase from '@/module/category/domain/usecase/change_category_usecase';
import CategoryController from '@/module/category/controller/category_controller';
import fetchProviderRepository from '@/module/provider/data/repository/fetch_provider_repository';
import fetchProviderUseCase from '@/module/provider/domain/usecase/fetch_provider_usecase';
import deleteProviderRepository from '@/module/provider/data/repository/delete_provider_repository';
import deleteProviderUseCase from '@/module/provider/domain/usecase/delete_provider_usecase';
import createProviderRepository from '@/module/provider/data/repository/create_provider_repository';
import createProviderUseCase from '@/module/provider/domain/usecase/create_provider_usecase';
import changeProviderRepository from '@/module/provider/data/repository/change_provider_repository';
import changeProviderUseCase from '@/module/provider/domain/usecase/change_provider_usecase';
import ProviderController from '@/module/provider/controller/provider_controller';
import fetchCompositeProductRepository from '@/module/compositeProduct/data/repository/fetch_composite_product_repository';
import fetchCompositeProductUseCase from '@/module/compositeProduct/domain/usecase/fetch_composite_product_usecase';
import deleteCompositeProductRepository from '@/module/compositeProduct/data/repository/delete_composite_producy_repository';
import deleteCompositeProductUseCase from '@/module/compositeProduct/domain/usecase/delete_composite_product_usecase';
import createCompositeProductRepository from '@/module/compositeProduct/data/repository/create_composite_product_repository';
import createCompositeProductUseCase from '@/module/compositeProduct/domain/usecase/create_composite_product_usecase';
import changeCompositeProductRepository from '@/module/compositeProduct/data/repository/change_composite_product_repository';
import changeCompositeProductUseCase from '@/module/compositeProduct/domain/usecase/change_composite_product_usecase';
import CompositeProductController from '@/module/compositeProduct/controller/composite_product_controller';
import fetchSimpleProductByCategoryRepository from '@/module/simpleProduct/data/repository/fetch_simple_product_by_category_repository';
import fetchSimpleProductByCategoryUseCase from '@/module/simpleProduct/domain/usecase/fetch_simple_product_by_category_usecase';
import { generateCompositeProductReportUseCase } from '@/module/compositeProduct/domain/usecase/generate_composite_product_report_usecase';
import fetchCashFlowRepository from '@/module/cashFlow/data/repository/fetch_cash_flow_repository';
import fetchCashFlowUseCase from '@/module/cashFlow/domain/usecase/fetch_cash_flow_usecase';
import createCashFlowRepository from '@/module/cashFlow/data/repository/create_cash_flow_repository';
import createCashFlowUseCase from '@/module/cashFlow/domain/usecase/create_cash_flow_usecase';
import CashFlowController from '@/module/cashFlow/controller/cash_flow_controller';
import fetchInventoryBySimpleProductIDUseCase from '@/module/inventory/domain/usecase/fetch_inventory_by_simple_product_id_usecase';
import fetchInventoryByCompositeProductIDUseCase from '@/module/inventory/domain/usecase/fetch_inventory_by_composite_product_id_usecase';
import fetchInventoryBySimpleProductIDRepository from '@/module/inventory/data/repository/fetch_inventory_by_simple_product_id_repository';
import { generateProductionOrderUseCase } from '@/module/compositeProduct/domain/usecase/generate_production_order_usecase';

const loginRepositoryImpl = loginRepostory(axosInstance)
const loginUseCaseImpl = loginUseCase(loginRepositoryImpl)

const fetchAccounPermissionsRepositoryImpl = fetchAccounPermissionsRepository(axosInstance)
const fetchAccountPermissionsUseCaseImpl = fetchAccountPermissionsUseCase(fetchAccounPermissionsRepositoryImpl)

const fetchUserRepositoryImpl = fetchUserRepository(axosInstance)
const deleteUserRepositoryImpl = deleteUserRepository(axosInstance)
const fetchUserUseCaseImpl = fetchUserUseCase(fetchUserRepositoryImpl)
const deleteUserUseCaseImpl = deleteUserUseCase(deleteUserRepositoryImpl)
const createUserRepositoryImpl = createUserRepository(axosInstance)
const createUserUseCaseImpl = createUserUseCase(createUserRepositoryImpl)
const changeUserRepositoryImpl = changeUserRepository(axosInstance)
const changeUserUseCaseImpl = changeUserUseCase(changeUserRepositoryImpl)

const fetchCityRepositoryImpl = fetchCityRepository(axosInstance)
const fetchCityUseCaseImpl = fetchCityUseCase(fetchCityRepositoryImpl)

const fetchCompanyRepositoryImpl = fetchCompanyRepository(axosInstance)
const fetchCompanyUseCaseImpl = fetchCompanyUseCase(fetchCompanyRepositoryImpl)
const deleteCompanyRepositoryImpl = deleteCompanyRepository(axosInstance)
const deleteCompanyUseCaseImpl = deleteCompanyUseCase(deleteCompanyRepositoryImpl)
const createCompanyRepositoryImpl = createCompanyRepository(axosInstance)
const createCompanyUseCaseImpl = createCompanyUseCase(createCompanyRepositoryImpl)
const changeCompanyRepositoryImpl = changeCompanyRepository(axosInstance)
const changeCompanyUseCaseImpl = changeCompanyUseCase(changeCompanyRepositoryImpl)
const fetchCnpjRepositoryImpl = fetchCnpjRepository(axosInstance)
const fetchCnpjUseCaseImpl = fetchCnpjUseCase(fetchCnpjRepositoryImpl)
const fetchPublicCompanyRepositoryImpl = fetchPublicCompanyRepository(axosInstance)
const fetchPublicCompanyUseCaseImpl = fetchPublicCompanyUseCase(fetchPublicCompanyRepositoryImpl)

const fetchRulerLogsRepositoryImpl = fetchRulerLogsRepository(axosInstance)
const fetchRulerLogsUseCaseImpl = fetchRulerLogsUseCase(fetchRulerLogsRepositoryImpl)

const getCompanyByIDRepositoryImpl = getCompanyByIDRepository(axosInstance)
const getCompanyByIDUsecaseImpl = getCompanyByIDUseCase(getCompanyByIDRepositoryImpl)

const fetchAccessPlanRepositoryImpl = fetchAccessPlanRepository(axosInstance)
const fetchAccessPlanUseCaseImpl = fetchAccessPlanUseCase(fetchAccessPlanRepositoryImpl)
const deleteAccessPlanRepositoryImpl = deleteAccessPlanRepository(axosInstance)
const deleteAccessPlanUseCaseImpl = deleteAccessPlanUseCase(deleteAccessPlanRepositoryImpl)
const createAccessPlanRepositoryImpl = createAccessPlanRepository(axosInstance)
const createAccessPlanUseCaseImpl = createAccessPlanUseCase(createAccessPlanRepositoryImpl)
const changeAccessPlanRepositoryImpl = changeAccessPlanRepository(axosInstance)
const changeAccessPlanUseCaseImpl = changeAccessPlanUseCase(changeAccessPlanRepositoryImpl)

const fetchPreRegistrationRepositoryImpl = fetchPreRegistrationRepository(axosInstance)
const fetchPreRegistrationUseCaseImpl = fetchPreRegistrationUseCase(fetchPreRegistrationRepositoryImpl)

const sendMailRecoveryRepositoryImpl = sendMailRecoveryRepository(axosInstance)
const sendMailRecoveryUseCaseImpl = sendMailRecoveryUseCase(sendMailRecoveryRepositoryImpl)

const fetchSimpleProductByCategoryRepositoryImpl = fetchSimpleProductByCategoryRepository(axosInstance)
const fetchSimpleProductByCategoryUseCaseImpl = fetchSimpleProductByCategoryUseCase(fetchSimpleProductByCategoryRepositoryImpl)
const fetchSimpleProductRepositoryImpl = fetchSimpleProductRepository(axosInstance)
const fetchSimpleProductUseCaseImpl = fetchSimpleProductUseCase(fetchSimpleProductRepositoryImpl)
const deleteSimpleProductRepositoryImpl = deleteSimpleProductRepository(axosInstance)
const deleteSimpleProductUseCaseImpl = deleteSimpleProductUseCase(deleteSimpleProductRepositoryImpl)
const createSimpleProductRepositoryImpl = createSimpleProductRepository(axosInstance)
const createSimpleProductUseCaseImpl = createSimpleProductUseCase(createSimpleProductRepositoryImpl)
const changeSimpleProductRepositoryImpl = changeSimpleProductRepository(axosInstance)
const changeSimpleProductUseCaseImpl = changeSimpleProductUseCase(changeSimpleProductRepositoryImpl)

const fetchCategoryRepositoryImpl = fetchCategoryRepository(axosInstance)
const fetchCategoryUseCaseImpl = fetchCategoryUseCase(fetchCategoryRepositoryImpl)
const deleteCategoryRepositoryImpl = deleteCategoryRepository(axosInstance)
const deleteCategoryUseCaseImpl = deleteCategoryUseCase(deleteCategoryRepositoryImpl)
const createCategoryRepositoryImpl = createCategoryRepository(axosInstance)
const createCategoryUseCaseImpl = createCategoryUseCase(createCategoryRepositoryImpl)
const changeCategoryRepositoryImpl = changeCategoryRepository(axosInstance)
const changeCategoryUseCaseImpl = changeCategoryUseCase(changeCategoryRepositoryImpl)

const fetchProviderRepositoryImpl = fetchProviderRepository(axosInstance)
const fetchProviderUseCaseImpl = fetchProviderUseCase(fetchProviderRepositoryImpl)
const deleteProviderRepositoryImpl = deleteProviderRepository(axosInstance)
const deleteProviderUseCaseImpl = deleteProviderUseCase(deleteProviderRepositoryImpl)
const createProviderRepositoryImpl = createProviderRepository(axosInstance)
const createProviderUseCaseImpl = createProviderUseCase(createProviderRepositoryImpl)
const changeProviderRepositoryImpl = changeProviderRepository(axosInstance)
const changeProviderUseCaseImpl = changeProviderUseCase(changeProviderRepositoryImpl)


const fetchCashFlowRepositoryImpl = fetchCashFlowRepository(axosInstance)
const fetchCashFlowUseCaseImpl = fetchCashFlowUseCase(fetchCashFlowRepositoryImpl)
const createCashFlowRepositoryImpl = createCashFlowRepository(axosInstance)
const createCashFlowUseCaseImpl = createCashFlowUseCase(createCashFlowRepositoryImpl)

const fetchCompositeProductRepositoryImpl = fetchCompositeProductRepository(axosInstance)
const fetchCompositeProductUseCaseImpl = fetchCompositeProductUseCase(fetchCompositeProductRepositoryImpl)
const deleteCompositeProductRepositoryImpl = deleteCompositeProductRepository(axosInstance)
const deleteCompositeProductUseCaseImpl = deleteCompositeProductUseCase(deleteCompositeProductRepositoryImpl)
const createCompositeProductRepositoryImpl = createCompositeProductRepository(axosInstance)
const createCompositeProductUseCaseImpl = createCompositeProductUseCase(createCompositeProductRepositoryImpl)
const changeCompositeProductRepositoryImpl = changeCompositeProductRepository(axosInstance)
const changeCompositeProductUseCaseImpl = changeCompositeProductUseCase(changeCompositeProductRepositoryImpl)
const generateCompositeProductReportUseCaseImpl = generateCompositeProductReportUseCase()
const generateProductionOrderUseCaseImpl = generateProductionOrderUseCase()

const fetchInventoryBySimpleProductIDRepositoryImpl = fetchInventoryBySimpleProductIDRepository(axosInstance)
const fetchInventoryBySimpleProductIDUseCaseImpl = fetchInventoryBySimpleProductIDUseCase(fetchInventoryBySimpleProductIDRepositoryImpl)
const fetchInventoryByCompositeProductIDRepositoryImpl = fetchInventoryByCompositeProductIDRepository(axosInstance)
const fetchInventoryByCompositeProductIDUseCaseImpl = fetchInventoryByCompositeProductIDUseCase(fetchInventoryByCompositeProductIDRepositoryImpl)


const accessPlanController = (context: any) => new AccessPlanController(
    context,
    fetchAccessPlanUseCaseImpl,
    deleteAccessPlanUseCaseImpl,
    createAccessPlanUseCaseImpl,
    changeAccessPlanUseCaseImpl,
)
const dashboardController = (context: any) => new DashboardController(
    context,
)
const defaultController = (context: any) => new DefaultController(
    context,
    refreshUseCaseImpl
);
const loginController = (context: any) => new LoginController(
    context,
    loginUseCaseImpl,
)
const homeController = (context: any) => new HomeController(
    context,
    fetchAccountPermissionsUseCaseImpl,
);
const userController = (context: any) => new UserController(
    context,
    fetchUserUseCaseImpl,
    deleteUserUseCaseImpl,
    createUserUseCaseImpl,
    changeUserUseCaseImpl,
    fetchCompanyUseCaseImpl,
    fetchPreRegistrationUseCaseImpl
)

const companyController = (context: any) => new CompanyController(
    context,
    fetchCityUseCaseImpl,
    fetchCompanyUseCaseImpl,
    deleteCompanyUseCaseImpl,
    createCompanyUseCaseImpl,
    changeCompanyUseCaseImpl,
    fetchCnpjUseCaseImpl,
)

const logsController = (context: any) => new LogsController(
    context,
    fetchRulerLogsUseCaseImpl,
)


const newAccountController = (context: any) => new NewAccountController(
    context,
    fetchPublicCompanyUseCaseImpl,
    createUserUseCaseImpl,
    refreshUseCaseImpl,
    loginUseCaseImpl,
)

const introController = (context: any) => new IntroController(
    context,
    refreshUseCaseImpl,
    fetchCnpjUseCaseImpl,
    fetchCityUseCaseImpl,
    createCompanyUseCaseImpl,
    changeUserUseCaseImpl,
    fetchAccessPlanUseCaseImpl,
    changeCompanyUseCaseImpl,
    getCompanyByIDUsecaseImpl
)

const passwordRecoveryController = (context: any) => new PasswordRecoveryController(
    context,
    sendMailRecoveryUseCaseImpl,
    changeUserUseCaseImpl
)

const simpleProductController = (context: any) => new SimpleProductController(
    context,
    fetchSimpleProductUseCaseImpl,
    deleteSimpleProductUseCaseImpl,
    createSimpleProductUseCaseImpl,
    changeSimpleProductUseCaseImpl,
    fetchCategoryUseCaseImpl,
    fetchProviderUseCaseImpl,
    fetchSimpleProductByCategoryUseCaseImpl,
    fetchInventoryBySimpleProductIDUseCaseImpl
)

const compositeProductController = (context: any) => new CompositeProductController(
    context,
    fetchCompositeProductUseCaseImpl,
    deleteCompositeProductUseCaseImpl,
    createCompositeProductUseCaseImpl,
    changeCompositeProductUseCaseImpl,
    fetchCategoryUseCaseImpl,
    fetchSimpleProductUseCaseImpl,
    generateCompositeProductReportUseCaseImpl,
    fetchInventoryByCompositeProductIDUseCaseImpl,
    generateProductionOrderUseCaseImpl,
)

const categoryController = (context: any) => new CategoryController(
    context,
    fetchCategoryUseCaseImpl,
    deleteCategoryUseCaseImpl,
    createCategoryUseCaseImpl,
    changeCategoryUseCaseImpl,
)

const providerController = (context: any) => new ProviderController(
    context,
    fetchProviderUseCaseImpl,
    deleteProviderUseCaseImpl,
    createProviderUseCaseImpl,
    changeProviderUseCaseImpl,
    fetchCityUseCaseImpl
)

const cashFlowController = (context: any) => new CashFlowController(
    context,
    fetchCashFlowUseCaseImpl,
    createCashFlowUseCaseImpl,
)

export {
    defaultController,
    loginController,
    homeController,
    userController,
    companyController,
    logsController,
    dashboardController,
    accessPlanController,
    newAccountController,
    introController,
    passwordRecoveryController,
    simpleProductController,
    categoryController,
    providerController,
    compositeProductController,
    cashFlowController
}

