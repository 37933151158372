import { FetchRulerLogUseCase } from './../domain/usecase/fetch_logs_usecase';
import { LogsPagination } from './../domain/model/logs_pagination';

import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { snackbar } from '@/core/controller/snackbar_controller';
class LogsController {
    public context: any;
    public dialog: boolean = false;
    public columns: Array<any> = headers
    public search: string = ""
    public logsPagination: LogsPagination = {
        total: 0,
        items: []
    }
    public options: DataOptions = {
        page: 1,
        itemsPerPage: 10,
        groupBy: [],
        sortBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: []
    }


    private fetchLogsUseCase: FetchRulerLogUseCase

    constructor(context: any, fetchLogsUseCase: FetchRulerLogUseCase,) {
        this.context = context
        this.fetchLogsUseCase = fetchLogsUseCase
    }

    async paginate() {
        try {
            this.logsPagination = await this.fetchLogsUseCase(this.options, this.search)
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    watchOptions() {
        this.paginate()
    }
}

export default LogsController