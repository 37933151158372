import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { CreateSimpleProductRepository } from '../../data/repository/create_simple_product_repository';
import { SimpleProduct } from '../model/simpleProduct';

export interface CreateSimpleProductUseCase {
    (data: SimpleProduct): Promise<SimpleProduct>
}

const createSimpleProductUseCase = (repository: CreateSimpleProductRepository): CreateSimpleProductUseCase => async (data: SimpleProduct) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createSimpleProductUseCase;

