<template>
  <div>
    <v-data-table
      id="composite_product"
      class="elevation-2 ma-2"
      dense
      height="73vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.optionsCompositeProduct"
      :server-items-length="controller.compositeProductPagination.total"
      :items="controller.compositeProductPagination.items"
      :loading="controller.loading"
      :mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.totalCost`]="{ item }">
        {{ toCurrency(item.totalCost) }}
      </template>

      <template v-slot:[`item.distributorValue`]="{ item }">
        {{ toCurrency(item.distributorValue) }} - <strong>{{ item.distributorMargin }} %</strong>
      </template>

      <template v-slot:[`item.resaleValue`]="{ item }">
        {{ toCurrency(item.resaleValue) }} - <strong>{{ item.resaleMargin }} %</strong>
      </template>

      <template v-slot:[`item.producerValue`]="{ item }">
        {{ toCurrency(item.producerValue) }} - <strong>{{ item.producerMargin }} %</strong>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.showInventory(item)"
            class="px-2"
          >
            <v-icon
              small
              color="primary"
            >mdi-receipt-text-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.exportPDF(item)"
            class="px-2"
          >
            <v-icon
              small
              color="orange"
            >mdi-file-eye</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="controller.exportProductWithPartsToExcel(item)"
            class="px-2"
          >
            <v-icon
              small
              color="green"
            >mdi-hammer</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">Produtos compostos</v-toolbar-title>
          <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          <v-text-field
            class="px-2"
            outlined
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-btn
            class="mx-2"
            color="green"
            dark
            height="40"
            :loading="controller.loadExcel"
            @click.stop="controller.exportExcel()"
          >
            <v-icon left>mdi-file-excel</v-icon>
            excel
          </v-btn>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="1400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção do produto composto
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row
                    no-gutters
                    class="mb-3"
                  >
                    <v-col>
                      <v-divider class="mt-4" />
                    </v-col>
                    <v-col class="text-center"> Configurações de compra </v-col>
                    <v-col>
                      <v-divider class="mt-3" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      lg="2"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Código"
                        v-model="controller.compositeProduct.code"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Nome do produto"
                        v-model="controller.compositeProduct.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      class="py-0"
                    >
                      <v-autocomplete
                        outlined
                        dense
                        color="primary"
                        label="Categoria"
                        v-model="controller.compositeProduct.categoryID"
                        :items="controller.categoryPagination.items"
                        item-value="id"
                        item-text="description"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      lg="2"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        :readonly="controller.compositeProduct.id != 0"
                        type="number"
                        color="primary"
                        label="Quantidade"
                        v-model.number="controller.compositeProduct.amount"
                      >
                      </v-text-field>
                    </v-col>
                    <v-row
                      no-gutters
                      class="mb-3"
                    >
                      <v-col>
                        <v-divider class="mt-4" />
                      </v-col>
                      <v-col class="text-center"> Itens que compoem o produto </v-col>
                      <v-col>
                        <v-divider class="mt-3" />
                      </v-col>
                    </v-row>
                    <v-col cols="12">

                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-autocomplete
                            outlined
                            dense
                            v-model="controller.simpleProductCombo"
                            :items="controller.simpleProductNewPagination.items"
                            label="Pesquise os produtos simples aqui..."
                            item-text="name"
                            return-object
                            @click:append="controller.addSimpleProduct()"
                            :search-input.sync="controller.searchSimpleProduct"
                            append-icon="mdi-plus"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-autocomplete
                            outlined
                            dense
                            v-model="controller.compositeProductCombo"
                            :items="controller.compositeProductNewPagination.items"
                            label="Pesquise os produtos compostos aqui..."
                            item-text="name"
                            append-icon="mdi-plus"
                            :search-input.sync="controller.searchCompositeProduct"
                            @click:append="controller.addCompositeProduct()"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-data-table
                        :headers="controller.columnsParts"
                        :items="controller.compositeProduct.compositeProductParts"
                        disable-pagination
                        hide-default-footer
                        :mobile-breakpoint="0"
                        dense
                      >
                        <template v-slot:[`item.totalCost`]="{ item }">
                          {{ toCurrency(item.totalCost) }}
                        </template>
                        <template v-slot:[`item.finalCost`]="{ item }">
                          <strong>{{ toCurrency(item.finalCost) }}</strong>
                        </template>
                        <template v-slot:[`item.conversionFactor`]="{ item }">
                          <v-select
                            hide-details
                            single-line
                            dense
                            solo
                            :items="[{id: 1, name: 'Dividir'}, {id: 2, name: 'Multiplicar'}]"
                            v-model.number="item.conversionFactor"
                            item-value="id"
                            item-text="name"
                            @input="controller.calculateFinalCost(item)"
                          >

                          </v-select>
                        </template>

                        <template v-slot:[`item.conversion`]="{ item }">
                          <v-text-field
                            dense
                            hide-details
                            single-line
                            solo
                            class="my-1"
                            type="number"
                            v-model="item.conversion"
                            @input="controller.calculateFinalCost(item)"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <div>
                            <v-btn
                              icon
                              @click="controller.deleteCompositePart(item)"
                              class="px-2"
                            >
                              <v-icon
                                small
                                color="red"
                              >mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12">
                      <v-row
                        no-gutters
                        class="mb-3"
                      >
                        <v-col>
                          <v-divider class="mt-4" />
                        </v-col>
                        <v-col class="text-center"> Configurações de venda </v-col>
                        <v-col>
                          <v-divider class="mt-3" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        color="primary"
                        label="Margem Distribuidor"
                        prefix="%"
                        v-model.number="controller.compositeProduct.distributorMargin"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        color="primary"
                        label="Margem Revenda"
                        prefix="%"
                        v-model.number="controller.compositeProduct.resaleMargin"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        color="primary"
                        label="Margem Produtor"
                        prefix="%"
                        v-model.number="controller.compositeProduct.producerMargin"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-row class="pa-0 ma-0">
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ? 12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="ICMS(Venda)"
                          prefix="%"
                          v-model.number="controller.compositeProduct.imcsSale"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ? 12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="PIS/CONFINS"
                          prefix="%"
                          v-model.number="controller.compositeProduct.pisCofins"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ? 12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="Despesas"
                          prefix="%"
                          v-model.number="controller.compositeProduct.expenses"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ? 12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="Comissão"
                          prefix="%"
                          v-model.number="controller.compositeProduct.commission"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ? 12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="IPI"
                          prefix="%"
                          v-model.number="controller.compositeProduct.ipiSale"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog
      max-width="800"
      persistent
      v-model="controller.inventoryDialog"
    >
      <v-card>
        <v-card-title class="primary white--text">
          Inventário
          <v-spacer></v-spacer>
          <v-icon
            @click="controller.inventoryDialog = false"
            color="white"
          >mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
            height="500"
            fixed-header
            :mobile-breakpoint="0"
            :footer-props="{
              'items-per-page-options': [40, 80],
            }"
            :headers="controller.inventoryHeaders"
            :items="controller.inventory.items"
          >
            <template v-slot:[`item.createdDate`]="{ item }">
              {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm:ss") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { compositeProductController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: compositeProductController(context),
  }),
  watch: {
    "controller.optionsCompositeProduct"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.searchSimpleProduct": _.debounce(function () {
      this.controller.watchSearchSimpleProduct();
    }, 500),
    "controller.searchCompositeProduct": _.debounce(function () {
      this.controller.watchSearchCompositeProduct();
    }, 500),
  },
};
</script>