<template>
  <v-app>
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        v-if="!$vuetify.breakpoint.mobile"
        cols="12"
        md="6"
        class="pa-0 ma-0"
      >
        <div class="screen-left d-flex justify-center">

          <v-img
            contain
            max-width="350"
            src="gobitz_white.png"
          ></v-img>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            sm="8"
            lg="7"
          >
            <v-card
              elevation="0"
              :class="{ 'pa-8': $vuetify.breakpoint.mobile, 'pa-0': !$vuetify.breakpoint.mobile }"
            >
              <v-card-text>
                <v-form ref="form">
                  <div class="d-flex justify-center align-center flex-column mb-10">
                    <!-- <v-img
                  src="/logo.png"
                  max-height="150"
                  max-width="150"
                >
                </v-img> -->
                  </div>
                  <v-text-field
                    label="Usuário"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                    outlined
                    v-model="controller.username"
                  ></v-text-field>
                  <v-text-field
                    label="Senha"
                    outlined
                    v-model="controller.password"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                    type="password"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <div class="primary--text text-center">
                        <v-btn
                          x-small
                          text
                          href="/password-recovery"
                          target="_blank"
                        >Esqueci minha senha</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-btn
                    @click="controller.login()"
                    class="mt-5"
                    color="primary"
                    block
                    height="50"
                    elevation="0"
                    :loading="controller.loading"
                  >Entrar</v-btn>
                  <v-btn
                    to="/account"
                    class="mt-2"
                    color="primary"
                    block
                    height="50"
                    outlined
                    :disabled="controller.loading"
                  >Criar conta</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { loginController } from "@/di/di";
export default {
  data: (context) => ({
    controller: loginController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>

<style scoped>
.screen-left {
  min-width: 100%;
  height: 100vh;
  background-color: var(--v-primary-base);
  border-radius: 0 40px 140px 0;
}
</style>
