export const headers = [
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Máximo de réguas',
    align: 'end',
    sortable: true,
    value: 'maxRulerRegistration',
  },
  {
    text: 'Máximo de medições de réguas',
    align: 'end',
    sortable: true,
    value: 'maxRulerMesurement',
  },
  {
    text: 'Máximo de levantes',
    align: 'end',
    sortable: true,
    value: 'maxPlaceRegistration',
  },

  {
    text: 'Máximo de bombas',
    align: 'end',
    sortable: true,
    value: 'maxPumpRegistration',
  },
  {
    text: 'Máximo de medições de bombas por levante',
    align: 'end',
    sortable: true,
    value: 'maxPumpMesurement',
  },
  {
    text: 'Máximo de colaboradores',
    align: 'end',
    sortable: true,
    value: 'maxEmployeeRegistration',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false
}
]
