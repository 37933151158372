import { Account } from './../../../../core/domain/model/account';
import { FetchPreRegistrationRepository } from './../../data/repository/fetch_pre_registration_repository';

export interface FetchPreRegistrationUseCase {
    (): Promise<Array<Account>>
}

const fetchPreRegistrationUseCase = (repository: FetchPreRegistrationRepository): FetchPreRegistrationUseCase => async () => {
    try {
        return await repository();
    } catch (error) {
        throw error
    }
}

export default fetchPreRegistrationUseCase;