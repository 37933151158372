import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { ChangeSimpleProductRepository } from '../../data/repository/change_simple_product_repository';
import { SimpleProduct } from '../model/simpleProduct';

export interface ChangeSimpleProductUseCase {
    (id: number, data: SimpleProduct): Promise<SimpleProduct>
}

const changeSimpleProductUseCase = (repository: ChangeSimpleProductRepository): ChangeSimpleProductUseCase => async (id:number, data: SimpleProduct) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeSimpleProductUseCase;

