import { getJwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { RefreshRepository } from "@/core/data/repository/refresh_repository"

export interface RefreshUseCase {
    (): Promise<boolean>
}

const refreshUseCase = (repository: RefreshRepository): RefreshUseCase => async () => {
    try {
        const jwtAuthToken = getJwtAuthToken()
        const newJwtAuthToken = await repository(jwtAuthToken)
        localStorage.setItem('auth', JSON.stringify(newJwtAuthToken))
        return true
    } catch (error) {
        return false
    }
}

export default refreshUseCase