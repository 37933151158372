import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { ChangeCompositeProductRepository } from '../../data/repository/change_composite_product_repository';
import { CompositeProduct } from '../model/compositeProduct';

export interface ChangeCompositeProductUseCase {
    (id: number, data: CompositeProduct): Promise<CompositeProduct>
}

const changeCompositeProductUseCase = (repository: ChangeCompositeProductRepository): ChangeCompositeProductUseCase => async (id:number, data: CompositeProduct) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeCompositeProductUseCase;

