import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { CreateProviderRepository } from '../../data/repository/create_provider_repository';
import { Provider } from '../model/provider';

export interface CreateProviderUseCase {
    (data: Provider): Promise<Provider>
}

const createProviderUseCase = (repository: CreateProviderRepository): CreateProviderUseCase => async (data: Provider) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createProviderUseCase;

