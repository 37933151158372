import { DeleteSimpleProductRepository } from '../../data/repository/delete_simple_producy_repository';
import { SimpleProduct } from '../model/simpleProduct';

export interface DeleteSimpleProductUseCase {
    (id: number): Promise<SimpleProduct>
}

const deleteSimpleProductUseCase = (repository: DeleteSimpleProductRepository): DeleteSimpleProductUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteSimpleProductUseCase;