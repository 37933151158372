
import { FetchCnpjRepository } from '../../data/repository/fetch_cnpj_repository';
import { FetchCnpj } from '../model/fetchCnpj';

export interface FetchCnpjUseCase {
    (cnpj: string): Promise<FetchCnpj>
}

const fetchCnpjUseCase = (repository: FetchCnpjRepository): FetchCnpjUseCase => async (cnpj: string) => {
    try {
        return await repository(cnpj);
    } catch (error) {
        throw error
    }
}

export default fetchCnpjUseCase;