import { UserPagination } from '../model/user_pagination';
import { FetchUserRepository } from '../../data/repository/fetch_user_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";

export interface FetchUsersUseCase {
    (pagination: DataOptions, search: string): Promise<UserPagination>
}

const fetchUsersUseCase = (repository: FetchUserRepository): FetchUsersUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchUsersUseCase;