import { getAccountGroupID, getAccountName } from '@/core/domain/model/jwtAuthToken';
import { snackbar } from '@/core/controller/snackbar_controller';
import UserPage from '../../user/view/user_page.vue'
import AccessPlanPage from '../../accessPlan/view/accessPlan.vue'
import DashboardPage from '../../dashboard/view/dashboard_page.vue'
import SimpleProduct from '../../simpleProduct/view/simpleProduct.vue'
import CompositeProduct from '../../compositeProduct/view/compositeProduct.vue'
import Category from '../../category/view/category.vue'
import Provider from '../../provider/view/provider.vue'
import CashFlow from '../../cashFlow/view/cash_flow.vue'
import { FetchAccountPermissionsUseCase } from '@/core/domain/usecase/fetch_account_permissions_usecase'
import { DataOptions } from 'vuetify';

class HomeController {
    public context: any;
    public selected: any = null;
    public mini: boolean = true
    public sidebarMenu: boolean = true
    public userMenu: boolean = false
    public fetchAccountPermissionsUseCase: FetchAccountPermissionsUseCase
    public dark: boolean = false
    public userName: string = ""
    public loading: boolean = true;
    public termosDialog: boolean = false
    public termosContext: any;
    public profileImage: any;
    public previewImage: any;
    public notificationMenu: boolean = false
    public search: string = ""
    public countNotifications: number = 0

    public options: DataOptions = {
        page: 1,
        itemsPerPage: 30,
        groupBy: [],
        sortBy: ["createDate"],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: [true]
    }


    constructor(
        context: any,
        fetchAccountPermissionsUseCase: FetchAccountPermissionsUseCase,
    ) {
        this.context = context;
        this.fetchAccountPermissionsUseCase = fetchAccountPermissionsUseCase;
    }

    async navigate(page: number) {
        switch (page) {
            case 0:
                this.selected = DashboardPage
                break
            case 1:
                this.selected = SimpleProduct
                break
            case 2:
                this.selected = CompositeProduct
                break
            case 3:
                this.selected = Category
                break
            case 4:
                this.selected = Provider
                break
            case 5:
                this.selected = UserPage
                break
            case 6:
                this.selected = AccessPlanPage
                break
            case 7:
                this.userMenu = !this.userMenu
                break
            case 8:
                this.selected = CashFlow
                break
            default:
                break
        }

        if (this.context.$vuetify.breakpoint.mobile) {
            this.sidebarMenu = false
        }

    }

    async openMenu() {
        this.sidebarMenu = false
        await new Promise((resolver: any) => setTimeout(resolver(), 200))
        this.sidebarMenu = true
    }

    logout() {
        localStorage.removeItem('auth');
        window.router.login()
    }

    async mounted() {
        try {
            this.dark = localStorage.getItem("dark") === "true" ? true : false;

            window.navigation = this;
            const accountGroupID = getAccountGroupID()

            // Vue.prototype.permissions = await this.fetchAccountPermissionsUseCase(
            //     accountGroupID
            // );

            this.userName = getAccountName()

            // if (!hadPermission(5, 1)) {
            //     this.selected = RulerMark
            // } else {
            //     this.selected = DashboardPage
            // }

            this.selected = SimpleProduct

            setTimeout(() => {
                this.loading = false
            }, 500)
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    openUserMenu() {
        this.userMenu = !this.userMenu
        this.notificationMenu = false
    }

    openNotificationMenu() {
        this.notificationMenu = !this.notificationMenu
        this.userMenu = false
    }



    termos() {
        this.userMenu = !this.userMenu
        this.termosDialog = true
    }


    /*     previewFile() {
            let preview = document.getElementById("imagem");
            let file = document.querySelector("input[type=file]").files[0];
            this.profileImage = file;
            let reader = new FileReader();
        
            reader.onloadend = function() {
              preview.src = reader.result;
            };
        
            this.previewImage = reader.readAsDataURL(file);
        
                if (file) {
              reader.readAsDataURL(file);
            } else {
              preview.src = "";
            } 
          } */
}

export default HomeController