export const headers = [
  {
    text: 'Fornecedor',
    align: 'start',
    sortable: true,
    value: 'surname',
  },
  {
    text: 'Razão social',
    align: 'start',
    sortable: true,
    value: 'corporateName',
  },
  {
    text: 'E-mail',
    align: 'start',
    sortable: true,
    value: 'email',
  },
  {
    text: 'Telefone',
    align: 'start',
    sortable: true,
    value: 'phone',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '110px'
  }
]
