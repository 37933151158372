<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="73vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.optionsCashFlow"
      :server-items-length="controller.cashFlowPagination.total"
      :items="controller.cashFlowPagination.items"
      :loading="controller.loading"
      :mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >

      <template v-slot:[`item.cashFlowTypeName`]="{ item }">
        <v-chip small class="white--text" :color="item.cashFlowTypeID == 1 ? 'green' : 'red'">{{ item.cashFlowTypeName }}</v-chip>
      </template>

      <template v-slot:[`item.createdDate`]="{ item }">
        {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm:ss") }}
      </template>

      <template v-slot:[`item.value`]="{ item }">
        {{ toCurrency(item.value) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">Fluxo de caixa</v-toolbar-title>
          <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          <v-text-field
            class="px-2"
            outlined
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Incluir transação
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      lg="6"
                      cols="12"
                      class="py-0"
                    >
                      <v-select
                        outlined
                        dense
                        label="Tipo do fluxo"
                        :items="controller.cashFlowsType"
                        v-model="controller.cashFlow.cashFlowTypeID"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-col>
                    <v-col
                      lg="6"
                      cols="12"
                      class="py-0"
                    >
                      <vuetify-money
                        outlined
                        dense
                        color="primary"
                        label="Valor"
                        v-model.number="controller.cashFlow.value"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-textarea
                        label="Descrição"
                        dense
                        outlined
                        v-model="controller.cashFlow.description"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { cashFlowController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: cashFlowController(context),
  }),
  watch: {
    "controller.optionsCashFlow"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
  },
};
</script>