import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Category } from '../../domain/model/category';

export interface CreateCategoryRepository {
  (data: Category): Promise<Category>
}

const createCategoryRepository = (axios: AxiosInstance): CreateCategoryRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/category`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createCategoryRepository

