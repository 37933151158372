export const headers = [
    {
        text: 'ID régua',
        value: 'rulerID'
    },
    {
        text: 'Data criação',
        value: 'createdDate'
    },
    {
        text: 'Nivel bateria',
        value: 'batery'
    },
    {
        text: 'Nível',
        value: 'level'
    },
    {
        text: 'Temperatura',
        value: 'temperature'
    },
    {
        text: 'Empresa',
        value: 'companyName'
    },
]