import { Company } from './../../../company/domain/model/company';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';


export interface GetCompanyByIDRepository {
    (id: number): Promise<Company>
}

const getCompanyByIDRepository = (axios: AxiosInstance): GetCompanyByIDRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/company/${id}`)
        const company: Company = response.data
        return company
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default getCompanyByIDRepository

