import animationData from '../../assets/happy.json'
class AlertController {
    public message: string;
    public dialog: boolean = false;
    public lottieAnimation: object = { animationData: animationData }

    constructor() {
        this.message = ''
    }

    show({
        message = '',
    }) {       
        this.message = message
        this.dialog = true
    }

    async confirm() {
        this.dialog = false
    }

}

const alert = new AlertController()
export { alert }