import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { CreateCompositeProductRepository } from '../../data/repository/create_composite_product_repository';
import { CompositeProduct } from '../model/compositeProduct';

export interface CreateCompositeProductUseCase {
    (data: CompositeProduct): Promise<CompositeProduct>
}

const createCompositeProductUseCase = (repository: CreateCompositeProductRepository): CreateCompositeProductUseCase => async (data: CompositeProduct) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createCompositeProductUseCase;

