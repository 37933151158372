<template>
  <div>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="85vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.accessPlanPagination.total"
      :items="controller.accessPlanPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-start">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Planos de acesso</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção Plano de Acesso
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <!-- <v-checkbox
                    v-model="controller.accessPlan.active"
                    color="primary"
                    label="Ativo"
                  ></v-checkbox> -->
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Nome do plano"
                    v-model="controller.accessPlan.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    type="number"
                    label="Valor"
                    v-model.number="controller.accessPlan.price"
                    :rules="[(v) => {
                        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999999999) return true;
                        return 'Preencha o campo';
                      }]"
                    prefix="R$"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    type="number"
                    dense
                    color="primary"
                    label="Número máximo de réguas"
                    hint="Use -1 para liberar acesso total"
                    persistent-hint
                    v-model.number="controller.accessPlan.maxRulerRegistration"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    type="number"
                    dense
                    color="primary"
                    label="Número máximo de medições de réguas"
                    hint="Use -1 para liberar acesso total"
                    persistent-hint
                    v-model.number="controller.accessPlan.maxRulerMesurement"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    type="number"
                    dense
                    color="primary"
                    label="Número máximo de levantes"
                    hint="Use -1 para liberar acesso total"
                    persistent-hint
                    v-model.number="controller.accessPlan.maxPlaceRegistration"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    type="number"
                    dense
                    color="primary"
                    label="Número máximo de bombas"
                    hint="Use -1 para liberar acesso total"
                    persistent-hint
                    v-model.number="controller.accessPlan.maxPumpRegistration"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    type="number"
                    dense
                    color="primary"
                    label="Número máximo de medições de bombas por levante"
                    hint="Use -1 para liberar acesso total"
                    persistent-hint
                    v-model.number="
                      controller.accessPlan.maxPumpMesurement
                    "
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    type="number"
                    color="primary"
                    label="Número máximo de colaboradores"
                    hint="Use -1 para liberar acesso total"
                    persistent-hint
                    v-model.number="
                      controller.accessPlan.maxEmployeeRegistration
                    "
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-textarea
                    v-model="controller.accessPlan.description"
                    label="Descrição"
                    filled
                    dense
                    color="primary"
                  >
                  </v-textarea>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { accessPlanController } from "@/di/di";
import _ from "lodash";
export default {
  data: (context) => ({
    controller: accessPlanController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
  },
};
</script>