export const numberRule = (v: any) => {
    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
    return 'Valor entre 0 e 100';
}

export const numberRuleConversion = (v: any) => {
    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 500) return true;
    return 'Valor entre 0 e 500';
}

