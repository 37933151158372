import { FetchInventoryByCompositeProductIDUseCase } from './../../inventory/domain/usecase/fetch_inventory_by_composite_product_id_usecase';
import { GenerateCompositeProductReportUseCase } from './../domain/usecase/generate_composite_product_report_usecase';
import { FetchSimpleProductUseCase } from '../../simpleProduct/domain/usecase/fetch_simple_product_usecase';
import { SimpleProduct, simpleProductDefault } from '@/module/simpleProduct/domain/model/simpleProduct';
import { GenerateProductionOrderUseCase } from '../domain/usecase/generate_production_order_usecase';
import { SimpleProductPagination } from '../../simpleProduct/domain/model/simpleProductPagination';
import { DeleteCompositeProductUseCase } from '../domain/usecase/delete_composite_product_usecase';
import { ChangeCompositeProductUseCase } from '../domain/usecase/change_composite_product_usecase';
import { CreateCompositeProductUseCase } from '../domain/usecase/create_composite_product_usecase';
import { FetchCompositeProductUseCase } from '../domain/usecase/fetch_composite_product_usecase';
import { CompositeProduct, compositeProductDefault } from '../domain/model/compositeProduct';
import { FetchCategoryUseCase } from '../../category/domain/usecase/fetch_category_usecase';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';
import { InventoryPagination } from './../../inventory/domain/model/inventoryPagination';
import { CompositeProductPagination } from '../domain/model/compositeProductPagination';
import { CategoryPagination } from '../../category/domain/model/category_pagination';
import { CompositeProductPart } from '../domain/model/compositeProduct';
import { snackbar } from '@/core/controller/snackbar_controller';
import { headers, headersParts } from '../const/headers';
import { headersInventory } from './../const/headers';
import { DataOptions } from 'vuetify';
import * as XLSX from "xlsx";


class CompositeProductController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public columnsParts: Array<any> = headersParts
  public search: string = ""
  public searchSimpleProduct: string = ""
  public searchCompositeProduct: string = ""
  public loading: boolean = false
  public compositeProductPagination: CompositeProductPagination = defaultModelPagination()
  public simpleProductNewPagination: SimpleProductPagination = defaultModelPagination()
  public compositeProductNewPagination: CompositeProductPagination = defaultModelPagination()
  public categoryPagination: CategoryPagination = defaultModelPagination()
  public optionsCompositeProduct: DataOptions = defaultPagination({})
  public optionsCompositeProductNew: DataOptions = defaultPagination({ sortBy: ["name"] })
  public optionsSimpleProductNew: DataOptions = defaultPagination({ sortBy: ["name"] })
  public optionsCategory: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["description"] })
  public compositeProduct: CompositeProduct = compositeProductDefault()
  public compositeProductCombo: CompositeProduct = compositeProductDefault()
  public simpleProductCombo: SimpleProduct = simpleProductDefault()
  public loadExcel: boolean = false
  public inventory: InventoryPagination = defaultModelPagination()
  public inventoryDialog: boolean = false
  public inventoryHeaders: Array<any> = headersInventory
  public optionsInventory: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["createdDate"], sortDesc: [true] })



  constructor(
    private context: any,
    private fetchCompositeProductUseCase: FetchCompositeProductUseCase,
    private deleteCompositeProductUseCase: DeleteCompositeProductUseCase,
    private createCompositeProductUseCase: CreateCompositeProductUseCase,
    private changeCompositeProductUseCase: ChangeCompositeProductUseCase,
    private fetchCategoryUseCase: FetchCategoryUseCase,
    private fetchSimpleProductUseCase: FetchSimpleProductUseCase,
    private generateCompositeProductReportUseCase: GenerateCompositeProductReportUseCase,
    private fetchInventoryByCompositeProductIDUseCase: FetchInventoryByCompositeProductIDUseCase,
    private generateProductionOrderUseCase: GenerateProductionOrderUseCase
  ) { }


  close() {
    this.compositeProduct = compositeProductDefault()
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.compositeProductPagination = await this.fetchCompositeProductUseCase(this.optionsCompositeProduct, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async watchSearchSimpleProduct() {
    this.optionsSimpleProductNew.itemsPerPage = 48
    if (this.searchSimpleProduct) {
      this.optionsSimpleProductNew.itemsPerPage = -1
    }

    const simples = await this.fetchSimpleProductUseCase(this.optionsSimpleProductNew, this.searchSimpleProduct)
    this.simpleProductNewPagination.items = simples.items.map((item: SimpleProduct) => {
      return {
        ...item,
        name: item.code + " - " + item.name,
      }
    })
  }

  async watchSearchCompositeProduct() {
    this.optionsCompositeProductNew.itemsPerPage = 48
    if (this.searchCompositeProduct) {
      this.optionsCompositeProductNew.itemsPerPage = -1
    }
    const composite = await this.fetchCompositeProductUseCase(this.optionsCompositeProductNew, this.searchCompositeProduct)

    this.compositeProductNewPagination.items = composite.items.map((item: CompositeProduct) => {
      return {
        ...item,
        name: item.code + " - " + item.name,
      }
    })
  }


  async open() {
    try {
      this.categoryPagination = await this.fetchCategoryUseCase(this.optionsCategory, "")
      this.watchSearchSimpleProduct()
      this.watchSearchCompositeProduct()
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.compositeProduct.id != 0) {
          await this.changeCompositeProductUseCase(this.compositeProduct.id, this.compositeProduct)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })

          this.dialog = false
          this.context.$refs.crud.resetValidation()
          return
        }

        await this.createCompositeProductUseCase(this.compositeProduct)
        this.paginate()
        snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })

        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: CompositeProduct) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteCompositeProductUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: CompositeProduct) {
    this.open()
    this.compositeProduct = { ...item }
    this.dialog = true
  }

  addCompositeProduct() {
    if (this.compositeProductCombo.id === 0) {
      snackbar.show({ message: "Selecione um produto antes!" })
      return
    }

    this.compositeProduct.compositeProductParts.push({
      productID: this.compositeProductCombo.id,
      companyID: this.compositeProductCombo.companyID,
      conversionFactor: 1,
      conversion: 1,
      productType: 2,
      code: this.compositeProductCombo.code,
      name: this.compositeProductCombo.name,
      totalCost: this.compositeProductCombo.totalCost,
      finalCost: this.compositeProductCombo.totalCost,
      itemOrder: this.compositeProduct.compositeProductParts.length + 1
    })

    this.compositeProductCombo = compositeProductDefault()
    this.simpleProductCombo = simpleProductDefault()
  }

  addSimpleProduct() {
    if (this.simpleProductCombo.id === 0) {
      snackbar.show({ message: "Selecione um produto antes!" })
      return
    }

    this.compositeProduct.compositeProductParts.push({
      productID: this.simpleProductCombo.id,
      companyID: this.simpleProductCombo.companyID,
      conversionFactor: 1,
      conversion: 1,
      productType: 1,
      code: this.simpleProductCombo.code,
      name: this.simpleProductCombo.name,
      totalCost: this.simpleProductCombo.totalCost,
      finalCost: this.simpleProductCombo.totalCost,
      itemOrder: this.compositeProduct.compositeProductParts.length + 1
    })

    this.compositeProductCombo = compositeProductDefault()
    this.simpleProductCombo = simpleProductDefault()
  }

  calculateFinalCost(item: CompositeProductPart) {
    item.conversion = parseFloat(item.conversion.toString())

    if (item.conversionFactor === 1) {
      item.finalCost = item.totalCost / item.conversion
      return
    }

    item.finalCost = item.totalCost * item.conversion
  }

  deleteCompositePart(item: CompositeProductPart) {
    this.compositeProduct.compositeProductParts = this.compositeProduct.compositeProductParts.filter((part) => part.code !== item.code)
  }

  async exportExcel() {
    try {
      const heading = [['Código', 'Produto', 'Categoria', 'Custo total', 'Distribuidor', 'Percentual Distribuidor', 'Revenda', 'Percentual Revenda', 'Produtor', 'Percentual Produtor']];
      this.loadExcel = true
      const paginate = defaultPagination(this.optionsCompositeProduct)
      paginate.itemsPerPage = -1
      const data = await this.fetchCompositeProductUseCase(paginate, this.search)

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);

      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        return {
          code: item.code,
          name: item.name,
          categoryName: item.categoryName,
          totalCost: item.totalCost,
          distributorValue: item.distributorValue,
          distributorMargin: item.distributorMargin + "%",
          releaseValue: item.resaleValue,
          releaseMargin: item.resaleMargin + "%",
          producerValue: item.producerValue,
          producerMargin: item.producerMargin + "%",
        }
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'produtos');

      XLSX.writeFile(wb, `produtos_compostos.xlsx`);
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadExcel = false
    }
  }

  async exportPDF(item: CompositeProduct) {
    try {
      await this.generateCompositeProductReportUseCase(item)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async showInventory(item: SimpleProduct) {
    try {
      this.inventory = await this.fetchInventoryByCompositeProductIDUseCase(item.id, this.optionsInventory, "")

      this.inventoryDialog = true
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async exportProductWithPartsToExcel(item: CompositeProduct) {
    try {
      await this.generateProductionOrderUseCase(item)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }
}

export default CompositeProductController