import { DeleteCategoryRepository } from '../../data/repository/delete_category_repository';
import { Category } from '../model/category';

export interface DeleteCategoryUseCase {
    (id: number): Promise<Category>
}

const deleteCategoryUseCase = (repository: DeleteCategoryRepository): DeleteCategoryUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteCategoryUseCase;