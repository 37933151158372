import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import { CreateCashFlowRepository } from '../../data/repository/create_cash_flow_repository';
import { CashFlow } from '../model/cash_flow';

export interface CreateCashFlowUseCase {
    (data: CashFlow): Promise<CashFlow>
}

const createCashFlowUseCase = (repository: CreateCashFlowRepository): CreateCashFlowUseCase => async (data: CashFlow) => {
    try {
        data.companyID = getCompanyID() ?? 0
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createCashFlowUseCase;

