import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { AccessPlan } from '../../domain/model/access_plan';

export interface CreateAccessPlanRepository {
  (data: object): Promise<AccessPlan>
}

const createAccessPlanRepository = (axios: AxiosInstance): CreateAccessPlanRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/access-plan`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createAccessPlanRepository

