import { ChangeUserRepository } from './../../data/repository/change_user_repository';
import { User } from '../model/user';

export interface ChangeUserUseCase {
    (id: number, data: object): Promise<User>
}

const changeUserUseCase = (repository: ChangeUserRepository): ChangeUserUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeUserUseCase;

