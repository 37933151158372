import { InventoryPagination } from '../../domain/model/inventoryPagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchInventoryByCompositeProductIDRepository {
    (id: number, pagination: Pagination): Promise<InventoryPagination>
}

const fetchInventoryByCompositeProductIDRepository = (axios: AxiosInstance): FetchInventoryByCompositeProductIDRepository => async (id: number, pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get(`/inventory/${id}/composite-product`, {
            params: pagination
        })

        const inventoryPagination: InventoryPagination = response.data
        return inventoryPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchInventoryByCompositeProductIDRepository