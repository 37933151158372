import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { User } from '../../domain/model/user';

export interface CreateUserRepository {
  (data: object): Promise<User>
}

const createUserRepository = (axios: AxiosInstance): CreateUserRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/account`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createUserRepository

