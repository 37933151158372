import { SimpleProduct } from '../../domain/model/simpleProduct';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteSimpleProductRepository {
    (id: number): Promise<SimpleProduct>
}

const deleteSimpleProductRepository = (axios: AxiosInstance): DeleteSimpleProductRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/simple-product/${id}`)

        const simpleProduct: SimpleProduct = response.data
        return simpleProduct
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteSimpleProductRepository