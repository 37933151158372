import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { CashFlow } from '../../domain/model/cash_flow';

export interface CreateCashFlowRepository {
  (data: CashFlow): Promise<CashFlow>
}

const createCashFlowRepository = (axios: AxiosInstance): CreateCashFlowRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/cash-flow`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createCashFlowRepository

