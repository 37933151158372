import { SimpleProductPagination } from '../model/simpleProductPagination';
import { FetchSimpleProductRepository } from '../../data/repository/fetch_simple_product_repository';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";

export interface FetchSimpleProductUseCase {
    (pagination: DataOptions, search: string): Promise<SimpleProductPagination>
}

const fetchSimpleProductUseCase = (repository: FetchSimpleProductRepository): FetchSimpleProductUseCase => async (pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search ?? ""
        }
        return await repository(payload);
    } catch (error) {
        throw error
    }
}

export default fetchSimpleProductUseCase;