import { LogsPagination } from './../../domain/model/logs_pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Pagination } from '@/core/domain/model/pagination';
import Handler from '@/core/domain/usecase/handler';

export interface FetchRulerLogsRepository {
    (pagination: Pagination): Promise<LogsPagination>
}

const fetchRulerLogsRepository = (axios: AxiosInstance): FetchRulerLogsRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/ruler-tracking', {
            params: pagination,
        })

        const logsPagination: LogsPagination = response.data
        logsPagination.items = logsPagination.items ?? []
        return logsPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchRulerLogsRepository