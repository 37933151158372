import { CashFlowPagination } from '../../domain/model/cash_flow_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchCashFlowRepository {
    (pagination: Pagination): Promise<CashFlowPagination>
}

const fetchCashFlowRepository = (axios: AxiosInstance): FetchCashFlowRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/cash-flow', {
            params: pagination
        })

        const CashFlowPagination: CashFlowPagination = response.data
        return CashFlowPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchCashFlowRepository