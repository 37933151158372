import { CreateAccessPlanRepository } from '../../data/repository/create_access_plan_repository';
import { AccessPlan } from '../model/access_plan';

export interface CreateAccessPlanUseCase {
    (data: object): Promise<AccessPlan>
}

const createAccessPlanUseCase = (repository: CreateAccessPlanRepository): CreateAccessPlanUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createAccessPlanUseCase;

