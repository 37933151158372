import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Default from '../module/default/view/default_page.vue'
import PasswordRecovery from '../module/passwordRecovery/view/password_recovery.vue'
import NewAccountPage from '../module/newAccount/view/new_account_page.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Default',
    component: Default
  },
  // {
  //   path: '/account',
  //   name: 'Account',
  //   component: NewAccountPage
  // },
  // {
  //   path: '/company',
  //   name: 'Company',
  //   component: Default
  // },
  // {
  //   path: '/password-recovery',
  //   name: 'PasswordRecovery',
  //   component: PasswordRecovery
  // },
  // {
  //   path: '/password-recovery/:token',
  //   name: 'PasswordRecovery',
  //   component: PasswordRecovery
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
