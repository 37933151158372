export interface CompositeProductPart {
  productID: number
  companyID: number
  conversionFactor: number
  conversion: number
  productType: number
  code: string
  name: string
  totalCost: number
  finalCost: number
  itemOrder: number
}

export interface CompositeProduct {
  id: number
  code: string
  categoryID: number
  name: string
  distributorMargin: number
  resaleMargin: number
  producerMargin: number
  distributorValue: number
  resaleValue: number
  producerValue: number
  imcsSale: number
  pisCofins: number
  expenses: number
  commission: number
  ipiSale: number
  companyID: number
  compositeProductParts: CompositeProductPart[]
  totalCost: number
  categoryName: string

}

export const compositeProductDefault = (): CompositeProduct => {
  return {
    id: 0,
    code: "",
    categoryID: 0,
    name: "",
    distributorMargin: 0,
    resaleMargin: 0,
    producerMargin: 0,
    imcsSale: 0,
    pisCofins: 0,
    expenses: 0,
    commission: 0,
    ipiSale: 0,
    companyID: 0,
    compositeProductParts: [],
    totalCost: 0,
    distributorValue: 0,
    resaleValue: 0,
    producerValue: 0,
    categoryName: ''
  }
}
