import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { CompositeProduct } from '../../domain/model/compositeProduct';

export interface ChangeCompositeProductRepository {
  (id: number, data: CompositeProduct): Promise<CompositeProduct>
}

const changeCompositeProductRepository = (axios: AxiosInstance): ChangeCompositeProductRepository => async (id: number, data: CompositeProduct) => {
  try {
    const response: AxiosResponse = await axios.put(`/composite-product/${id}`,
      data
    )

    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeCompositeProductRepository

