import { alert } from '@/core/controller/alert_controller';
import { ChangeUserUseCase } from '@/module/user/domain/usecase/change_user_usecase';
import { SendMailRecoveryUseCase } from './../domain/usecase/send_mail_recovery_usecase';
import { snackbar } from '@/core/controller/snackbar_controller';
import { accountDefault, Account } from '@/core/domain/model/account';
import { expired, getAccountFromToken } from '@/core/domain/model/jwtAuthToken';
class PasswordRecoveryController {
    private context: any

    public changePass: boolean = false
    public expired: boolean = false
    public account: Account = accountDefault()
    public mail: string = ""
    public emailRuler = [
        (v: string) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail inválido',
        (v: string) => !!v || 'Preencha o campo'
    ]

    private sendMailRecoveryUseCase: SendMailRecoveryUseCase
    private changeUserUseCase: ChangeUserUseCase

    constructor(
        context: any,
        sendMailRecoveryUseCase: SendMailRecoveryUseCase,
        changeUserUseCase: ChangeUserUseCase,
    ) {
        this.context = context
        this.sendMailRecoveryUseCase = sendMailRecoveryUseCase
        this.changeUserUseCase = changeUserUseCase
    }

    mounted() {
        const token = this.context.$route.params.token

        if (token) {
            this.changePass = !expired(token)
            this.expired = expired(token)

            if (this.changePass) {
                this.account = getAccountFromToken(token)
            }
        }
    }

    async sendEmail() {
        if (this.context.$refs.formMail.validate()) {
            try {
                await this.sendMailRecoveryUseCase(this.mail)
                alert.show({ message: "Um e-mail foi enviado para você, verifique sua caixa de e-mail e siga as instruções" })
                this.mail = ""
            } catch (error: any) {
                snackbar.show({ message: error.toString() })
            }
        }

    }

    async updatePassword() {
        if (this.context.$refs.formPass.validate()) {
            try {
                localStorage.setItem('auth', JSON.stringify({ token: this.context.$route.params.token }))
                await this.changeUserUseCase(this.account.id, this.account)

                localStorage.clear()
                alert.show({ message: "Sua senha foi alterada com sucesso! Faça o login novamente" })
                this.context.$router.push('/')
            } catch (error: any) {
                snackbar.show({ message: error.toString() })
            }
        }
    }

    reset() {
        this.changePass = false
        this.expired = false
    }
}

export default PasswordRecoveryController