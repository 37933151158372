export interface Account {
    id: number;
    login: string;
    name: string;
    password: string;
    confirmationPassword: string;
    email: string;
    createdDate: Date | null;
    accountGroupID: number;
    active: boolean;
    companyID: number | null;
    companyName: string;
    preRegistration: boolean;
    accessPlanID: number | null
}

export const accountDefault = (): Account => {
    return {
        id: 0,
        login: "",
        name: "",
        password: "",
        email: "",
        accountGroupID: 0,
        active: true,
        companyID: null,
        companyName: "",
        createdDate: null,
        confirmationPassword: "",
        preRegistration: false,
        accessPlanID: null
    }
}