import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#E96326',
        secondary: '#208A39',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: "#E96326",
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});



