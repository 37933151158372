import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Company } from '../../domain/model/company';

export interface CreateCompanyRepository {
  (data: object): Promise<Company>
}

const createCompanyRepository = (axios: AxiosInstance): CreateCompanyRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/company`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default createCompanyRepository

