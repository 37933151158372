import { LoginRepository } from '@/core/data/repository/login_repository';
export interface LoginUseCase {
    (username: string, password: string): Promise<boolean>
}

const loginUseCase = (repository: LoginRepository): LoginUseCase => async (username: string, password: string) => {
    try {
        const jwtAuthToken = await repository(username, password)
        localStorage.setItem('auth', JSON.stringify(jwtAuthToken))
        return true
    } catch (error) {
        throw error
    }
}

export default loginUseCase