import { GetCompanyByIDUseCase } from './../../dashboard/domain/usecase/get_company_by_id_usecase';
import { ChangeCompanyUseCase } from './../../company/domain/usecase/change_company_usecase';
import { AccessPlanPagination } from './../../accessPlan/domain/model/access_plan_pagination';
import { FetchAccessPlanUseCase } from './../../accessPlan/domain/usecase/fetch_access_plan_usecase';
import { snackbar } from "@/core/controller/snackbar_controller";
import { getAccessPlan, getAccount, getAccountName, getCompanyID, isPreRegistration, JwtAuthToken } from "@/core/domain/model/jwtAuthToken";
import { defaultModelPagination, defaultPagination } from "@/core/domain/model/pagination";
import { RefreshUseCase } from "@/core/domain/usecase/refresh_usecase";
import { CityPagination } from "@/module/city/domain/model/city_pagination";
import { FetchCityUseCase } from "@/module/city/domain/usecase/fetch_city_usecase";
import { Company, companyDefault } from "@/module/company/domain/model/company";
import { CompanyPagination } from "@/module/company/domain/model/company_pagination";
import { CreateCompanyUseCase } from "@/module/company/domain/usecase/create_company_usecase";
import { FetchCnpjUseCase } from "@/module/company/domain/usecase/fetch_cnpj_usecase";
import { ChangeUserUseCase } from "@/module/user/domain/usecase/change_user_usecase";
import Typed from "typed.js";
import { DataOptions } from "vuetify";
import { AccessPlan } from '@/module/accessPlan/domain/model/access_plan';

class IntroController {
    private context: any
    public refreshButton: boolean = false
    public option: number = 1;
    public optionsCompany = defaultPagination({})
    public companyPagination: CompanyPagination = defaultModelPagination()
    public accessPlanPagination: AccessPlanPagination = defaultModelPagination()
    public loading: boolean = false
    public center: object | null = {
        lat: -13.956416,
        lng: -52.332572
    }
    public zoom: number = 10
    public cityPagination: CityPagination = defaultModelPagination()
    public optionsCity: DataOptions = defaultPagination({})
    public optionsPlan: DataOptions = defaultPagination({})
    public company: Company = companyDefault()
    public searchCity: string = ""

    private typedQuest: Typed | undefined
    private typedDesc: Typed | undefined
    private refreshUseCase: RefreshUseCase
    private fetchCnpjUseCase: FetchCnpjUseCase
    private fetchCityUseCase: FetchCityUseCase
    private createCompanyUseCase: CreateCompanyUseCase
    private changeUserUseCase: ChangeUserUseCase
    private fetchAccessPlanUseCase: FetchAccessPlanUseCase
    private changeCompanyUseCase: ChangeCompanyUseCase
    private getCompanyByIDUseCase: GetCompanyByIDUseCase

    constructor(
        context: any,
        refreshUseCase: RefreshUseCase,
        fetchCnpjUseCase: FetchCnpjUseCase,
        fetchCityUseCase: FetchCityUseCase,
        createCompanyUseCase: CreateCompanyUseCase,
        changeUserUseCase: ChangeUserUseCase,
        fetchAccessPlanUseCase: FetchAccessPlanUseCase,
        changeCompanyUseCase: ChangeCompanyUseCase,
        getCompanyByIDUseCase: GetCompanyByIDUseCase
    ) {
        this.context = context
        this.refreshUseCase = refreshUseCase
        this.fetchCnpjUseCase = fetchCnpjUseCase
        this.fetchCityUseCase = fetchCityUseCase
        this.createCompanyUseCase = createCompanyUseCase
        this.changeUserUseCase = changeUserUseCase
        this.fetchAccessPlanUseCase = fetchAccessPlanUseCase
        this.changeCompanyUseCase = changeCompanyUseCase
        this.getCompanyByIDUseCase = getCompanyByIDUseCase
    }

    async mounted() {
        this.typedDesc?.destroy()
        this.typedQuest?.destroy()
        this.typedQuest = new Typed("#quest1", {
            strings: [
                "Olá, <strong>" + getAccountName() + "</storng>",
            ],
            typeSpeed: 5,
            startDelay: 500,
            loop: false,
            showCursor: false
        });

        if (getCompanyID() === null && !isPreRegistration()) {
            this.option = 2
            this.typedDesc = new Typed("#desc1", {
                strings: [
                    "Por favor, cadastre sua empresa na plataforma Hydra.",
                ],
                startDelay: 1200,
                typeSpeed: 5,
                loop: false,
                showCursor: false,
                onComplete: () => {
                    this.refreshButton = true
                }
            });
        } else if (getAccessPlan() == null && !isPreRegistration()) {
            this.option = 3
            this.typedDesc = new Typed("#desc1", {
                strings: [
                    "Estamos quase lá! Por favor, escolha o plano de acesso desejado.",
                ],
                startDelay: 1200,
                typeSpeed: 5,
                loop: false,
                showCursor: false,
                onComplete: () => {
                    this.refreshButton = true
                }
            });
        } else {
            this.option = 1
            this.typedDesc = new Typed("#desc1", {
                strings: [
                    "Sua conta está aguardando liberação da empresa",
                ],
                startDelay: 1200,
                typeSpeed: 5,
                loop: false,
                showCursor: false,
                onComplete: () => {
                    this.refreshButton = true
                }
            });
        }

        this.accessPlanPagination = await this.fetchAccessPlanUseCase(this.optionsPlan, "")
    }

    logOut() {
        window.router.login()
    }

    async refreshStatus() {
        try {
            await this.refreshUseCase()
            if (!isPreRegistration()) {
                window.location.reload()
            } else {
                snackbar.show({ message: "Sua conta ainda não foi aprovada pelo gestor da empresa!", color: "green" })
            }
        } catch (error: any) {
            snackbar.show({ message: error })
        }
    }

    async createCompany() {
        if (this.context.$refs.formCompanyRegistration.validate()) {
            try {
                this.loading = true

                this.company.timezoneID = 1
                const company = await this.createCompanyUseCase(this.company)

                const account = getAccount()

                account.companyID = company.id

                await this.changeUserUseCase(account.id, account)

                await this.refreshUseCase()

                this.refreshButton = false
                this.option = 3
                this.typedDesc?.destroy()
                this.typedDesc = new Typed("#desc1", {
                    strings: [
                        "Estamos quase lá! Por favor, escolha o plano de acesso desejado.",
                    ],
                    startDelay: 1200,
                    typeSpeed: 5,
                    loop: false,
                    showCursor: false,
                    onComplete: () => {
                        this.refreshButton = true
                    }
                });
            } catch (error: any) {
                snackbar.show({ message: error.toString() })
            } finally {
                this.loading = false
            }
        }
    }

    async updateCompany(plan: AccessPlan) {
        try {
            const company = await this.getCompanyByIDUseCase()
            company.accessPlanID = plan.id
            await this.changeCompanyUseCase(company.id, company)

            await this.refreshUseCase()

            window.location.reload()
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }


    async getCnpj() {
        try {
            const cnpj = this.company.cpfCnpj.replace(/[^0-9]/g, '')

            const dataCnpj = await this.fetchCnpjUseCase(cnpj)

            if (dataCnpj.status != "ERROR") {
                this.searchCity = dataCnpj.municipio + " - " + dataCnpj.uf
                await this.paginateCity()
                this.company.name = dataCnpj.nome
                this.company.address = dataCnpj.logradouro
                this.company.comporateName = dataCnpj.fantasia
                this.company.cityID = this.cityPagination.items[0].id
                this.company.phone = dataCnpj.telefone
                this.company.latitude = dataCnpj.location.lat.toFixed(7)
                this.company.longitude = dataCnpj.location.lng.toFixed(7)
                this.center = dataCnpj.location
            } else {
                this.company = companyDefault()
                snackbar.show({ message: "CNPJ não encontrado!", color: "red" })
            }
        } catch (error: any) {
            snackbar.show({ message: "Verifique o CNPJ." })
        }
    }

    getLocation(value) {
        this.company.latitude = value.lat().toFixed(7)
        this.company.longitude = value.lng().toFixed(7)

        this.center = value
    }

    async paginateCity() {
        if (this.searchCity != null) {
            try {
                this.cityPagination = await this.fetchCityUseCase(this.optionsCity, this.searchCity)
            } catch (error: any) {
                snackbar.show({ message: error.toString() })
            }
        }
    }

    watchSearchCity() {
        this.paginateCity()
    }

}

export default IntroController