<template>
  <div
    style="width: 100%; background-color: red; position: relative;"
    :style="{height: height}"
  >
    <div
      style="width: 100%; background-color: red; position: relative;"
      :style="{height: height}"
    >
      <google-map
        ref="maps"
        :center="centerInternal"
        @center_changed="updateCenter"
        @dragend="dragEnd"
        @dragstart="dragStart"
        :zoom="zoom"
        style="width: 100%; height: 100%"
        map-type-id="hybrid"
        :options="$vuetify.theme.isDark ? mapDark : mapLight"
      >
      </google-map>
      <v-icon
        color="orange"
        x-large
        style="position: absolute; left: 50%; right: 50%; top: 48%; bottom:50%"
      >mdi-map-marker</v-icon>
      <v-btn
        fab
        small
        style="position: absolute; right: 10px; top: 10px;"
        @click="dialog('maps_dialog')"
        v-if="zoomIn"
      >
        <v-icon
          color="orange"
          small
        >mdi-arrow-expand</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        style="position: absolute; right: 10px; top: 10px;"
        @click="$emit('close')"
        v-else
      >
        <v-icon
          color="orange"
          small
        >mdi-close</v-icon>
      </v-btn>

      <v-btn
        color="orange"
        class="white--text"
        v-if="dragEnded"
        style="position: absolute; right: 10px; bottom: 10px; left: 10px"
        @click="getLocation(centerAux)"
      >
        <v-icon
          left
          small
        >mdi-map-marker</v-icon> definir local
      </v-btn>
    </div>

    <v-dialog
      v-model="expanded"
      max-height="700"
      max-width="1200"
    >
      <v-card height="700">
        <form-maps
          height="100%"
          :center="centerInternal"
          :zoom="zoom"
          :zoom-in="false"
          @get-location="getLocation"
          @close="close"
        ></form-maps>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "form-maps",
  data: (vm) => ({
    expanded: false,
    centerInternal: vm.center,
    centerAux: vm.center,
    dragEnded: false,
  }),
  mounted() {
    this.fitBounds("maps");
  },
  watch: {
    center() {
      this.centerInternal = this.center;
      this.centerAux = this.center;
    },
  },
  methods: {
    async dialog() {
      this.expanded = this.zoomIn;

      if (this.expanded) {
        await new Promise((resolver) => setTimeout(resolver, 200));
        this.fitBounds();
      }
    },
    fitBounds() {
      this.$refs.maps.$mapPromise.then((map) => {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.center);

        map.fitBounds(bounds);
      });
    },
    updateCenter(value) {
      this.centerAux = value;
    },
    dragEnd() {
      this.dragEnded = true;
    },
    dragStart() {
      this.dragEnded = false;
    },
    getLocation(value) {
      this.expanded = false;
      this.dragEnded = false;
      this.centerInternal = value;
      this.$emit("get-location", value);
    },
    close() {
      this.expanded = false;
    },
  },
  props: {
    zoom: {
      type: Number,
      default: () => 10,
    },
    height: {
      type: String,
      default: () => "300px",
    },
    center: {
      type: Object,
      default: () => ({
        lat: -13.956416,
        lng: -52.332572,
      }),
    },
    "zoom-in": {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
