import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { AccessPlan } from '../../domain/model/access_plan';

export interface ChangeAccessPlanRepository {
  (id: number, data: object): Promise<AccessPlan>
}

const changeAccessPlanRepository = (axios: AxiosInstance): ChangeAccessPlanRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/access-plan/${id}`,
      data
    )
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeAccessPlanRepository

