import { Permission } from './../../domain/model/permission';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface FetchAccounPermissionsRepository {
    (id: number): Promise<Array<Permission>>
}

const fetchAccounPermissionsRepository = (axios: AxiosInstance): FetchAccounPermissionsRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.get(`/account-group/${id}/permissions`)

        const permissions: Array<Permission> = response.data
        return permissions
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchAccounPermissionsRepository