import { CityPagination } from '@/module/city/domain/model/city_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { snackbar } from '@/core/controller/snackbar_controller';
import { CashFlow, cashFlowDefault } from '../domain/model/cash_flow';
import { CashFlowPagination } from '../domain/model/cash_flow_pagination';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';
import { FetchCashFlowUseCase } from '../domain/usecase/fetch_cash_flow_usecase';
import { CreateCashFlowUseCase } from '../domain/usecase/create_cash_flow_usecase';
import { FetchCityUseCase } from '@/module/city/domain/usecase/fetch_city_usecase';

class CashFlowController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCity: string = ""
  public loading: boolean = false
  public cashFlowPagination: CashFlowPagination = defaultModelPagination()
  public optionsCashFlow: DataOptions = defaultPagination({sortBy: ["createdDate"], sortDesc: [true]})
  public cashFlow: CashFlow = cashFlowDefault()

  cashFlowsType = [
    {
      id: 1,
      name: "RECEITA"
    },
    {
      id: 2,
      name: "DESPESA"
    }
  ]

  constructor(
    private context: any,
    private fetchCashFlowUseCase: FetchCashFlowUseCase,
    private createCashFlowUseCase: CreateCashFlowUseCase,
  ) { }


  close() {
    this.cashFlow = cashFlowDefault()
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.cashFlowPagination = await this.fetchCashFlowUseCase(this.optionsCashFlow, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }


  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        await this.createCashFlowUseCase(this.cashFlow)
        this.paginate()
        snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })

        this.close()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async change(item: CashFlow) {
    this.cashFlow = { ...item }
    this.dialog = true
  }
}

export default CashFlowController