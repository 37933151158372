import { DeleteCompanyRepository } from './../../data/repository/delete_company_repository';
import { Company } from '../model/company';

export interface DeleteCompanyUseCase {
    (id: number): Promise<Company>
}

const deleteCompanyUseCase = (repository: DeleteCompanyRepository): DeleteCompanyUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteCompanyUseCase;