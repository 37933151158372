import { FetchSimpleProductByCategoryRepository } from './../../data/repository/fetch_simple_product_by_category_repository';
import { SimpleProductPagination } from '../model/simpleProductPagination';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";

export interface FetchSimpleProductByCategoryUseCase {
    (id: number, pagination: DataOptions, search: string): Promise<SimpleProductPagination>
}

const fetchSimpleProductByCategoryUseCase = (repository: FetchSimpleProductByCategoryRepository): FetchSimpleProductByCategoryUseCase => async (id: number, pagination: DataOptions, search: string) => {
    try {
        const payload: Pagination = {
            sort: pagination.sortBy.join(','),
            descending: pagination.sortDesc.join(','),
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            search: search ?? ""
        }
        return await repository(id, payload);
    } catch (error) {
        throw error
    }
}

export default fetchSimpleProductByCategoryUseCase;