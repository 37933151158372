import { FetchPublicCompanyUseCase } from "@/module/company/domain/usecase/fetch_public_company_usecase";
import { defaultModelPagination, defaultPagination } from "@/core/domain/model/pagination";
import { CompanyPagination } from "@/module/company/domain/model/company_pagination";
import { snackbar } from "@/core/controller/snackbar_controller";
import Typed from "typed.js";
import { alert } from "@/core/controller/alert_controller";
import { Account, accountDefault } from "@/core/domain/model/account";
import { Company, companyDefault } from "@/module/company/domain/model/company";
import { CreateUserUseCase } from "@/module/user/domain/usecase/create_user_usecase";
import { RefreshUseCase } from "@/core/domain/usecase/refresh_usecase";
import { LoginUseCase } from "@/core/domain/usecase/login_usecase";

class NewAccountController {
    private context: any;
    private fetchPublicCompanyUseCase: FetchPublicCompanyUseCase
    private createUserUseCase: CreateUserUseCase

    private refreshUseCase: RefreshUseCase
    private loginUseCase: LoginUseCase

    public emailRuler = [
        (v: string) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail inválido',
        (v: string) => !!v || 'Preencha o campo'
    ]
    public option: number = 0
    public optionsCompany = defaultPagination({})
    public companyPagination: CompanyPagination = defaultModelPagination()
    public showCompanies: boolean = false
    public showOptions: boolean = false
    public search: string = ""
    private typedQuest: Typed | undefined
    private typedDesc: Typed | undefined
    public newAccount: boolean = false
    public newCompany: boolean = false
    public account: Account = accountDefault()
    public company: Company = companyDefault()
    public loading: boolean = false

    constructor(
        context: any,
        fetchPublicCompanyUseCase: FetchPublicCompanyUseCase,
        createUserUseCase: CreateUserUseCase,
        refreshUseCase: RefreshUseCase,
        loginUseCase: LoginUseCase
    ) {
        this.context = context
        this.fetchPublicCompanyUseCase = fetchPublicCompanyUseCase
        this.createUserUseCase = createUserUseCase
        this.refreshUseCase = refreshUseCase
        this.loginUseCase = loginUseCase
    }

    watchSearch() {
        this.fetchCompanies()
    }

    mounted() {
        this.typedDesc?.destroy()
        this.typedQuest?.destroy()
        this.typedQuest = new Typed("#quest1", {
            strings: [
                "Qual vai ser seu papel dentro do ecossistema hydra?",
            ],
            typeSpeed: 5,
            startDelay: 500,
            loop: false,
            showCursor: false
        });

        this.typedDesc = new Typed("#desc1", {
            strings: [
                "Isso é importante para definirmos qual atitude tomar para melhorar sua experiência com a plataforma.",
            ],
            startDelay: 1200,
            typeSpeed: 5,
            loop: false,
            showCursor: false,
            onComplete: () => {
                this.showOptions = true
            }
        });
    }

    isProducer() {
        this.option = 1
        this.account.accountGroupID = 2

        this.typedDesc?.destroy()
        this.typedQuest?.destroy()
        this.typedQuest = new Typed("#quest1", {
            strings: [
                "Preencha seus dados de login!",
            ],
            typeSpeed: 5,
            startDelay: 500,
            loop: false,
            showCursor: false
        });

        this.typedDesc = new Typed("#desc1", {
            strings: [
                "É muito importante o preenchimento correto dos dados para a criação da conta.",
            ],
            startDelay: 1200,
            typeSpeed: 5,
            loop: false,
            showCursor: false,
            onComplete: () => {
                this.newAccount = true
            }
        });
    }

    async fetchCompanies() {
        try {
            this.option = 2
            this.companyPagination = await this.fetchPublicCompanyUseCase(this.optionsCompany, this.search)
        } catch (error: any) {
            snackbar.show({ message: error })
        }
    }

    async isCollaborator() {
        try {
            this.option = 2
            this.account.accountGroupID = 3
            await this.fetchCompanies()
        } catch (error: any) {
            snackbar.show({ message: error })
        } finally {
            this.typedDesc?.destroy()
            this.typedQuest?.destroy()
            this.typedQuest = new Typed("#quest1", {
                strings: [
                    "Em qual dessas empresas você trabalha?",
                ],
                typeSpeed: 5,
                startDelay: 500,
                loop: false,
                showCursor: false
            });

            this.typedDesc = new Typed("#desc1", {
                strings: [
                    "Por favor, é super importante essa informação para continuarmos o cadastro.",
                ],
                startDelay: 1200,
                typeSpeed: 5,
                loop: false,
                showCursor: false,
                onComplete: () => {
                    this.showCompanies = true
                }
            });


        }
    }

    selectedCompany(company: Company) {
        this.account.companyID = company.id
        this.account.preRegistration = true
        this.newAccount = true
        this.option = 1

        this.typedDesc?.destroy()
        this.typedQuest?.destroy()
        this.typedQuest = new Typed("#quest1", {
            strings: [
                "Preencha seus dados de login!",
            ],
            typeSpeed: 5,
            startDelay: 500,
            loop: false,
            showCursor: false
        });

        this.typedDesc = new Typed("#desc1", {
            strings: [
                "É muito importante o preenchimento correto dos dados para a criação da conta.",
            ],
            startDelay: 1200,
            typeSpeed: 5,
            loop: false,
            showCursor: false,
            onComplete: () => {
                this.newAccount = true
            }
        });
    }

    returnOptions() {
        if (this.option > 0) {
            this.context.$refs?.formRegistration?.resetValidation()
            this.newAccount = false
            this.showCompanies = false
            this.showOptions = false
            this.option = 0
            this.account = accountDefault()

            this.mounted()
        } else {
            this.context.$router.replace("/")
        }
    }

    copyToClipboard() {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText("https://sistemahydra.com.br/account");
        }

        alert.show({ message: "Copiamos o link para você, compartilhe com o dono da propriedade e informe para relaizar o cadastro. Qualquer duvida espamos a disposição." })
    }


    async create() {
        if (this.context.$refs.formRegistration.validate()) {
            try {
                this.loading = true
                await this.createUserUseCase(this.account)
                await this.loginUseCase(this.account.login, this.account.password)

                this.context.$router.push("/")
                this.newAccount = false
                this.newCompany = true

                this.context.$refs.form?.resetValidation()
                this.context.$refs.formRegistration?.resetValidation()
                this.context.$refs.formCompanyRegistration?.resetValidation()
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        }
    }
}

export default NewAccountController