import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { SimpleProduct } from '../../domain/model/simpleProduct';

export interface ChangeSimpleProductRepository {
  (id: number, data: SimpleProduct): Promise<SimpleProduct>
}

const changeSimpleProductRepository = (axios: AxiosInstance): ChangeSimpleProductRepository => async (id: number, data: SimpleProduct) => {
  try {
    const response: AxiosResponse = await axios.put(`/simple-product/${id}`,
      data
    )

    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeSimpleProductRepository

