import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface SendMailRecoveryRepository {
  (email: string)
}

const sendMailRecoveryRepository = (axios: AxiosInstance): SendMailRecoveryRepository => async (email: string) => {
  try {
    const response: AxiosResponse = await axios.post(`/password-recovery`,
      {
        email: email
      })
    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default sendMailRecoveryRepository

