import { AccessPlanPagination } from '../../domain/model/access_plan_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchAccessPlanRepository {
    (pagination: Pagination): Promise<AccessPlanPagination>
}

const fetchAccessPlanRepository = (axios: AxiosInstance): FetchAccessPlanRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/access-plan', {
            params: pagination,
        })

        const accessPlanPagination: AccessPlanPagination = response.data
        return accessPlanPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchAccessPlanRepository