<template>
  <div>
    <v-data-table
      id="simple_products"
      class="elevation-2 ma-2"
      dense
      height="73vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.optionsSimpleProduct"
      :server-items-length="controller.simpleProductPagination.total"
      :items="controller.simpleProductPagination.items"
      :loading="controller.loading"
      :mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.totalCost`]="{ item }">
        {{ toCurrency(item.totalCost) }}
      </template>

      <template v-slot:[`item.distributorValue`]="{ item }">
        {{ toCurrency(item.distributorValue) }} - <strong>{{ item.distributorMargin }} %</strong>
      </template>

      <template v-slot:[`item.resaleValue`]="{ item }">
        {{ toCurrency(item.resaleValue) }} - <strong>{{ item.resaleMargin }} %</strong>
      </template>

      <template v-slot:[`item.producerValue`]="{ item }">
        {{ toCurrency(item.producerValue) }} - <strong>{{ item.producerMargin }} %</strong>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.showInventory(item)"
            class="px-2"
          >
            <v-icon
              small
              color="primary"
            >mdi-receipt-text-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">Produtos simples</v-toolbar-title>
          <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          <v-text-field
            class="px-2"
            outlined
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
            style="max-width: 300px"
          ></v-text-field>
          <v-autocomplete
            hide-details
            class="mr-2"
            outlined
            dense
            color="primary"
            label="Categoria"
            @change="controller.paginateByCategory($event)"
            :items="controller.categoryPagination.items"
            item-value="id"
            item-text="description"
            clearable
            style="max-width: 300px"
          >
          </v-autocomplete>
          <v-btn
            class="mx-2"
            color="green"
            dark
            height="40"
            :loading="controller.loadExcel"
            @click.stop="controller.exportExcel()"
          >
            <v-icon left>mdi-file-excel</v-icon>
            excel
          </v-btn>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="1200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>
            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção do produto simples
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-row
                    no-gutters
                    class="mb-3"
                  >
                    <v-col>
                      <v-divider class="mt-4" />
                    </v-col>
                    <v-col class="text-center"> Configurações de compra </v-col>
                    <v-col>
                      <v-divider class="mt-3" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Código"
                        v-model="controller.simpleProduct.code"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      class="py-0"
                      cols="12"
                    >
                      <v-autocomplete
                        outlined
                        dense
                        color="primary"
                        label="Fornecedor"
                        v-model="controller.simpleProduct.providerID"
                        :items="controller.providerPagination.items"
                        item-value="id"
                        item-text="surname"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <v-autocomplete
                        outlined
                        dense
                        color="primary"
                        label="Categoria"
                        v-model="controller.simpleProduct.categoryID"
                        :items="controller.categoryPagination.items"
                        item-value="id"
                        item-text="description"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      lg="6"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        label="Nome do produto"
                        v-model="controller.simpleProduct.name"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      cols="12"
                      class="py-0"
                    >
                      <vuetify-money
                        outlined
                        dense
                        color="primary"
                        label="Preço"
                        v-model.number="controller.simpleProduct.price"
                        :rules="[(v) => !!v || 'Preencha o campo']"
                      />
                    </v-col>
                    <v-col
                      lg="2"
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        outlined
                        dense
                        :readonly="controller.simpleProduct.id != 0"
                        type="number"
                        color="primary"
                        label="Quantidade"
                        v-model.number="controller.simpleProduct.amount"
                      >
                      </v-text-field>
                    </v-col>
                    <v-row class="pa-0 ma-0">
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="Conversão"
                          v-model.number="controller.simpleProduct.conversion"
                          :rules="[numberRuleConversion]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-select
                          outlined
                          :items="controller.conversionMeasure"
                          item-value="id"
                          item-text="description"
                          dense
                          color="primary"
                          label="Medida de conversão"
                          :rules="[(v) => !!v || 'Preencha o campo']"
                          v-model.number="controller.simpleProduct.conversionMeasure"
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="Frete"
                          prefix="%"
                          v-model.number="controller.simpleProduct.freight"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="IPI"
                          prefix="%"
                          v-model.number="controller.simpleProduct.ipi"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="ST"
                          prefix="%"
                          v-model.number="controller.simpleProduct.st"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="ICMS(Compra)"
                          prefix="%"
                          v-model.number="controller.simpleProduct.imcsPurchase"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-col cols="12">
                      <v-row
                        no-gutters
                        class="mb-3"
                      >
                        <v-col>
                          <v-divider class="mt-4" />
                        </v-col>
                        <v-col class="text-center"> Configurações de venda </v-col>
                        <v-col>
                          <v-divider class="mt-3" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      lg="4"
                      class="py-0"
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        color="primary"
                        label="Margem Distribuidor"
                        prefix="%"
                        v-model.number="controller.simpleProduct.distributorMargin"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      class="py-0"
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        color="primary"
                        label="Margem Revenda"
                        prefix="%"
                        v-model.number="controller.simpleProduct.resaleMargin"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      lg="4"
                      class="py-0"
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        color="primary"
                        label="Margem Produtor"
                        prefix="%"
                        v-model.number="controller.simpleProduct.producerMargin"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-row class="pa-0 ma-0">
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="ICMS(Venda)"
                          prefix="%"
                          v-model.number="controller.simpleProduct.imcsSale"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="PIS/CONFINS"
                          prefix="%"
                          v-model.number="controller.simpleProduct.pisCofins"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="Despesas"
                          prefix="%"
                          v-model.number="controller.simpleProduct.expenses"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="Comissão"
                          prefix="%"
                          v-model.number="controller.simpleProduct.commission"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        :cols="$vuetify.breakpoint.mobile ?  12 : null"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          color="primary"
                          label="IPI"
                          prefix="%"
                          v-model.number="controller.simpleProduct.ipiSale"
                          :rules="[numberRule]"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog
      max-width="800"
      persistent
      v-model="controller.inventoryDialog"
    >
      <v-card>
        <v-card-title class="primary white--text">
          Inventário
          <v-spacer></v-spacer>
          <v-icon
            @click="controller.inventoryDialog = false"
            color="white"
          >mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
            height="500"
            fixed-header
            :mobile-breakpoint="0"
            :footer-props="{
              'items-per-page-options': [40, 80],
            }"
            :headers="controller.inventoryHeaders"
            :items="controller.inventory.items"
          >
            <template v-slot:[`item.createdDate`]="{ item }">
              {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm:ss") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { simpleProductController } from "@/di/di";
import { mask } from "vue-the-mask";
import _ from "lodash";

export default {
  directives: { mask },
  data: (context) => ({
    controller: simpleProductController(context),
  }),
  watch: {
    "controller.optionsSimpleProduct"() {
      this.controller.watchOptions();
    },
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
  },
};
</script>