import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';
import { FetchCnpj } from '../../domain/model/fetchCnpj';

export interface FetchCnpjRepository {
    (cnpj: string): Promise<FetchCnpj>
}

const fetchCnpjRepository = (axios: AxiosInstance): FetchCnpjRepository => async (cnpj: string) => {
    try {
        const response: AxiosResponse = await axios.get(`/company?cnpj=${cnpj}`)
        const data: FetchCnpj = response.data
        return data
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchCnpjRepository