import { SimpleProductPagination } from '../../domain/model/simpleProductPagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchSimpleProductByCategoryRepository {
    (id: number, pagination: Pagination): Promise<SimpleProductPagination>
}

const fetchSimpleProductByCategoryRepository = (axios: AxiosInstance): FetchSimpleProductByCategoryRepository => async (id: number, pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get(`/category/${id}/simple-product`, {
            params: pagination
        })

        const simpleProductPagination: SimpleProductPagination = response.data
        return simpleProductPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchSimpleProductByCategoryRepository