import { ChangeCompanyRepository } from './../../data/repository/change_company_repository';
import { Company } from '../model/company';

export interface ChangeCompanyUseCase {
    (id: number, data: object): Promise<Company>
}

const changeCompanyUseCase = (repository: ChangeCompanyRepository): ChangeCompanyUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeCompanyUseCase;

