import { headersInventory } from './../const/headers';
import { InventoryPagination } from './../../inventory/domain/model/inventoryPagination';
import { FetchInventoryBySimpleProductIDUseCase } from './../../inventory/domain/usecase/fetch_inventory_by_simple_product_id_usecase';
import { FetchSimpleProductByCategoryUseCase } from './../domain/usecase/fetch_simple_product_by_category_usecase';
import { FetchProviderUseCase } from './../../provider/domain/usecase/fetch_provider_usecase';
import { ProviderPagination } from './../../provider/domain/model/provider_pagination';
import { CategoryPagination } from './../../category/domain/model/category_pagination';
import { FetchCategoryUseCase } from './../../category/domain/usecase/fetch_category_usecase';

import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { snackbar } from '@/core/controller/snackbar_controller';
import { SimpleProduct, simpleProductDefault } from '../domain/model/simpleProduct';
import { SimpleProductPagination } from '../domain/model/simpleProductPagination';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';
import { FetchSimpleProductUseCase } from '../domain/usecase/fetch_simple_product_usecase';
import { DeleteSimpleProductUseCase } from '../domain/usecase/delete_simple_product_usecase';
import { ChangeSimpleProductUseCase } from '../domain/usecase/change_simple_product_usecase';
import { CreateSimpleProductUseCase } from '../domain/usecase/create_simple_product_usecase';
import * as XLSX from "xlsx";
class SimpleProductController {
  public inventory: InventoryPagination = defaultModelPagination()
  public inventoryDialog: boolean = false
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public inventoryHeaders: Array<any> = headersInventory
  public search: string = ""
  public loading: boolean = false
  public simpleProductPagination: SimpleProductPagination = defaultModelPagination()
  public categoryPagination: CategoryPagination = defaultModelPagination()
  public providerPagination: ProviderPagination = defaultModelPagination()
  public optionsSimpleProduct: DataOptions = defaultPagination({})
  public optionsCategory: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["description"] })
  public simpleProduct: SimpleProduct = simpleProductDefault()
  public optionsProvider: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["name"] })
  public optionsInventory: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["createdDate"], sortDesc: [true] })
  public loadExcel: boolean = false
  private categoryID: number | null = null
  public conversionMeasure = [
    {
      id: 1,
      description: "Peça (PC)"
    },
    {
      id: 2,
      description: "Quilograma (KG)"
    },
    {
      id: 3,
      description: "Metro (MT)"
    },
    {
      id: 4,
      description: "Conjunto (CJ)"
    },
    {
      id: 5,
      description: "Unidade (UN)"
    },
    {
      id: 6,
      description: "Hora (HR)"
    },
    {
      id: 7,
      description: "Caixa (CX)"
    },
    {
      id: 8,
      description: "Jogo (JG)"
    },
    {
      id: 9,
      description: "Par (PR)"
    },
    {
      id: 10,
      description: "Pacote (PT)"
    },
    {
      id: 11,
      description: "Litro (LT)"
    },
  ]

  constructor(
    private context: any,
    private fetchSimpleProductUseCase: FetchSimpleProductUseCase,
    private deleteSimpleProductUseCase: DeleteSimpleProductUseCase,
    private createSimpleProductUseCase: CreateSimpleProductUseCase,
    private changeSimpleProductUseCase: ChangeSimpleProductUseCase,
    private fetchCategoryUseCase: FetchCategoryUseCase,
    private fetchProviderUseCase: FetchProviderUseCase,
    private fetchSimpleProductByCategoryUseCase: FetchSimpleProductByCategoryUseCase,
    private fetchInventoryBySimpleProductIDUseCase: FetchInventoryBySimpleProductIDUseCase
  ) { }


  close() {
    this.simpleProduct = simpleProductDefault()
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    if (this.categoryID === null) {
      this.loading = true
      try {
        const [categories, products] = await Promise.all([
          this.fetchCategoryUseCase(this.optionsCategory, ""),
          this.fetchSimpleProductUseCase(this.optionsSimpleProduct, this.search)
        ])
        this.categoryPagination = categories
        this.simpleProductPagination = products
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.loading = false
      }
      return
    }

    this.paginateByCategory(this.categoryID!)
  }

  async paginateByCategory(id: number) {
    this.categoryID = id
    if (id) {
      this.loading = true
      try {
        this.simpleProductPagination = await this.fetchSimpleProductByCategoryUseCase(id, this.optionsSimpleProduct, this.search)
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.loading = false
      }

      return
    }

    this.paginate()
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async open() {
    try {
      const [categories, providers] = await Promise.all([
        this.fetchCategoryUseCase(this.optionsCategory, ""),
        this.fetchProviderUseCase(this.optionsProvider, ""),
      ])
      this.categoryPagination = categories
      this.providerPagination = providers
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.simpleProduct.id != 0) {
          await this.changeSimpleProductUseCase(this.simpleProduct.id, this.simpleProduct)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })

          this.dialog = false
          this.context.$refs.crud.resetValidation()
          return
        }

        await this.createSimpleProductUseCase(this.simpleProduct)
        this.paginate()
        snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })

        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: SimpleProduct) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteSimpleProductUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: SimpleProduct) {
    this.open()
    this.simpleProduct = { ...item }
    this.dialog = true
  }

  async exportExcel() {
    try {
      const heading = [['Código', 'Produto', 'Categoria', 'Custo total', 'Distribuidor', 'Percentual Distribuidor', 'Revenda', 'Percentual Revenda', 'Produtor', 'Percentual Produtor', 'ICMS Venda', 'PIS/COFINS', 'DESPESAS', 'COMISSÃO', 'IPI Venda', 'IPI']];
      this.loadExcel = true
      let data: SimpleProductPagination = defaultModelPagination()
      const paginate = defaultPagination(this.optionsSimpleProduct)
      paginate.itemsPerPage = -1
      if (this.categoryID) {
        data = await this.fetchSimpleProductByCategoryUseCase(this.categoryID, paginate, this.search)
      } else {
        data = await this.fetchSimpleProductUseCase(paginate, this.search)
      }

      const wb = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, heading);

      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        return {
          code: item.code,
          name: item.name,
          categoryName: item.categoryName,
          totalCost: item.totalCost,
          distributorValue: item.distributorValue,
          distributorMargin: item.distributorMargin + "%",
          releaseValue: item.resaleValue,
          releaseMargin: item.resaleMargin + "%",
          producerValue: item.producerValue,
          producerMargin: item.producerMargin + "%",
          imcsSale: item.imcsSale,
          pisCofins: item.pisCofins,
          expenses: item.expenses,
          commission: item.commission,
          ipiSale: item.ipiSale,
          ipi: item.ipi
        }
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'produtos');

      XLSX.writeFile(wb, `produtos_simples.xlsx`);
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loadExcel = false
    }
  }

  async showInventory(item: SimpleProduct) {
    try {
      this.inventory = await this.fetchInventoryBySimpleProductIDUseCase(item.id, this.optionsInventory, "")

      this.inventoryDialog = true
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }
}

export default SimpleProductController