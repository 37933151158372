import { CompanyPagination } from './../../domain/model/company_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchCompanyRepository {
    (pagination: Pagination): Promise<CompanyPagination>
}

const fetchCompanyRepository = (axios: AxiosInstance): FetchCompanyRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/company', {
            params: pagination
        })

        const companyPagination: CompanyPagination = response.data
        return companyPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchCompanyRepository