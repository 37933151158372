import { toCurrency } from '../../../../core/domain/usecase/currency';
import { CompositeProduct } from '../model/compositeProduct';
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export interface GenerateProductionOrderUseCase {
  (compositeProduct: CompositeProduct)
}


const generateProductionOrderUseCase = (): GenerateProductionOrderUseCase => async (compositeProduct: CompositeProduct) => {
  try {
    const parts: any =
      compositeProduct.compositeProductParts?.map((item, index) => {
        return [
          { text: '#' + item.itemOrder.toString() },
          { text: item.code ? item.code.toString() : '', },
          { text: item.name ? item.name.toString() : '' },
          { text: item.conversionFactor === 1 ? 'DIVIDIR' : 'MULTIPLICAR' },
          { text: item.conversion ? item.conversion.toString() : '' },
        ]
      }) ?? []

    parts.unshift([
      { text: '#' },
      { text: 'Código', width: 'auto' },
      { text: 'Produto', width: 'auto' },
      { text: 'Conversão', width: 'auto' },
      { text: 'Unidade', width: 'auto' },
    ])

    var docDefinition = {
      pageMargins: [25, 75, 25, 35],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            margin: [2, 10, 2, 2],
            columns: [
              {
                margin: [0, 0, 0, 0],
                text: `ORDEM DE PRODUÇÃO`,
                fontSize: 15,
                alignment: 'center',
                bold: true,
              },
            ],
          },
          {
            margin: 2,
            columns: [
              {
                margin: [0, 0, 0, 0],
                text: `${compositeProduct.name}`,
                fontSize: 12,
                alignment: 'center',
                bold: true,
              },
            ],
          },
          {
            margin: [0, 0, 0, 0],
            alignment: 'center',
            text: `Categoria: ${compositeProduct.categoryName}`,
            fontSize: 10,
            bold: true,
          },
          {
            margin: [0, 0, 0, 0],
            alignment: 'center',
            text: `Código: ${compositeProduct.code}`,
            fontSize: 10,
            bold: true,
          },
          // {
          //     canvas: [{ type: 'line', x1: 0, y1: 10, x2: 5000, y2: 10, lineWidth: 1 }]
          // }
        ]
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text:
                    '© Visão control. Pagina ' +
                    currentPage.toString() +
                    ' de ' +
                    pageCount + ' | ' + dayjs().format("DD/MM/YYYY - HH:mm"),
                },
              ],
              alignment: 'center',
            },
          ],
        }
      },
      pageOrientation: 'landscape',
      content: [
        {
          columns: [
            {
              text: 'Partes do produto',
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
            },
          ],
        },

        {
          margin: [0, 0, 0, 10],
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              if (rowIndex == 0) {
                return null
              }
              return rowIndex % 2 == 0 ? null : '#e5e5e5'
            },
          },
          fontSize: 10,
          table: {
            headerRows: 1,
            widths: [20, 50, '*', 100, 100],
            body: parts,
          },
        },
        {
          margin: [0, 0, 0, 20],
          columns: [
            {
              text: `Total de itens no produto composto: ${parts.length - 1}`,
              fontSize: 10,
              bold: true,
              alignment: 'left',
            },
          ],
        },
      ],
    }
    pdfMake.createPdf(docDefinition).open()
  } catch (error) {
    throw error
  }
}

export { generateProductionOrderUseCase }  