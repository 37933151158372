<template>
  <div class="background_screen">
    <v-app-bar
      clipped-left
      clipped-right
      app
      height="70"
    >
      <div>
        <v-img
          contain
          max-width="150"
          src="gobitz_default.png"
        ></v-img>
      </div>
      <v-spacer></v-spacer>

      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-flex justify-end align-center mr-0"
            v-bind="attrs"
            v-on="on"
          >
            <div class="mt-1 text-end">
              <div class="font-weight-bold headline">Gelmeza</div>
              <div class="text-subtitle-2">Sistema de custos</div>
            </div>
            <v-img
              contain
              max-height="60"
              max-width="110"
              src="logo.png"
            ></v-img>
          </div>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title class="text-center">Olá, <strong>{{ controller.userName }}</strong></v-list-item-title>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn
              outlined
              block
              color="primary"
              @click="controller.logout()"
            >
              <v-icon left>mdi-exit-to-app</v-icon> sair
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <template v-slot:extension>
        <v-tabs
          grow
          :show-arrows="$vuetify.breakpoint.mobile"
        >
          <v-tab
            class="primary--text"
            @click="controller.navigate(1)"
          >
            <v-icon
              color="primary"
              left
            >mdi-archive-outline</v-icon> Produtos simples
          </v-tab>
          <v-tab
            class="primary--text"
            @click="controller.navigate(2)"
          >
            <v-icon
              color="primary"
              left
            >mdi-archive-plus-outline</v-icon> Produtos compostos
          </v-tab>
          <v-tab
            class="primary--text"
            @click="controller.navigate(3)"
          >
            <v-icon
              color="primary"
              left
            >mdi-shape-outline</v-icon> Categorias
          </v-tab>
          <v-tab
            class="primary--text"
            @click="controller.navigate(4)"
          >
            <v-icon
              color="primary"
              left
            >mdi-truck-outline</v-icon> Fornecedores
          </v-tab>
          <v-tab
            class="primary--text"
            @click="controller.navigate(8)"
          >
            <v-icon
              color="primary"
              left
            >mdi-cash</v-icon> Fluxo de caixa
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <component :is="controller.selected"></component>
    </v-main>
    <!-- <v-navigation-drawer
      right
      :value="controller.userMenu"
      absolute
      style="z-index: 9999"
    >
      <div>
        <div class="ml-4 mb-6 d-flex flex-column align-start">
          <v-flex
            class="d-flex justify-space-between"
            style="width: 100%"
          >
            <h2 class="pt-4 font-weight-light">Olá</h2>
            <v-btn
              icon
              @click="controller.userMenu = false"
            >
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-flex>
          <h2 class="font-weight-medium">{{ controller.userName }}</h2>
        </div>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center align-center"
          >
            <div class="avatar-upload">
              <div class="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  @change="controller.previewFile()"
                />
                <label
                  for="imageUpload"
                  class="d-flex justify-center align-center"
                >
                  <v-icon size="20">mdi-image-edit-outline</v-icon>
                </label>
              </div>

              <v-avatar size="120">
                <img
                  id="imagem"
                  :src="
                    controller.imagemPerfil
                      ? controller.imagemPerfil
                      : `profile.png`
                  "
                  alt="Imagem de Perfil"
                />
              </v-avatar>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="mb-4 d-flex justify-center">
          <div>
            <v-switch
              inset
              hide-details
              v-model="controller.dark"
              color="primary"
              :label="controller.dark ? 'Modo escuro' : 'Modo claro'"
            ></v-switch>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <div
      @mouseenter="controller.mini = false"
      @mouseleave="controller.mini = true"
      v-if="!controller.loading"
    >
      <v-navigation-drawer
        :value="controller.sidebarMenu"
        left
        app
        clipped
        :mini-variant="controller.mini && !$vuetify.breakpoint.mobile"
        :temporary="$vuetify.breakpoint.mobile"
        color="primary"
        dark
        mini-variant-width="74"
      >
        <v-list
          dense
          nav
          subheader
        >
          <v-list-item-group
            class="mt-2"
            mandatory
            color="white"
          >
            <v-list-item
              @click="controller.navigate(0)"
              v-if="hadPermission(5, 1)"
            >
              <v-list-item-icon>
                <v-img
                  src="/logo.png"
                  max-height="50"
                  max-width="25"
                  contain
                ></v-img>
                
              </v-list-item-icon>
              <v-list-item-content>
                <v-img
                  src="/logo_branco2.png"
                  max-height="45"
                  max-width="70"
                  contain
                  eager
                ></v-img>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-icon>
                <v-img
                  src="/logo.png"
                  max-height="50"
                  max-width="25"
                  contain
                ></v-img>
                
              </v-list-item-icon>
              <v-list-item-content>
                <v-img
                  src="/logo_branco2.png"
                  max-height="45"
                  max-width="70"
                  contain
                  eager
                ></v-img>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="controller.navigate(1)">
              <v-list-item-icon>
                <v-icon>mdi-chart-timeline-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Categorias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controller.navigate(2)">
              <v-list-item-icon>
                <v-icon>mdi-water-pump</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fornecedores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-subheader
              class="mt-10"
              v-show="!controller.mini || $vuetify.breakpoint.mobile"
            >Configurações</v-subheader>
            <v-list-item @click="controller.navigate(1)">
              <v-list-item-icon>
                <v-icon>mdi-chart-timeline-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Categorias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="controller.navigate(2)">
              <v-list-item-icon>
                <v-icon>mdi-water-pump</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fornecedores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="mt-10"
              v-if="hadPermission(0, 0)"
              v-show="!controller.mini || $vuetify.breakpoint.mobile"
            >Documentações</v-subheader>
            <v-list-item
              @click="controller.navigate(11)"
              v-if="hadPermission(0, 0)"
            >
              <v-list-item-icon>
                <v-icon>mdi-text-box-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>API Swagger</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <template v-slot:append>
          <v-list dense>
            <v-list-item @click="controller.logout()">
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer> 
    </div>-->
  </div>
</template>

<script>
import { homeController } from "@/di/di";

export default {
  data: (context) => ({
    controller: homeController(context),
  }),
  async mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.dark"() {
      this.$vuetify.theme.dark = this.controller.dark;
      localStorage.setItem("dark", this.controller.dark);
    },
  },
};
</script>

<style scoped>
.card:hover {
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
}
.notification-card {
  top: 70px;
  float: right;
  position: fixed;
  /* margin-top: 70px; */
  right: 100px;
  width: 430px;
  z-index: 9999;
}
.avatar-card {
  top: 70px;
  float: right;
  position: fixed;
  /* margin-top: 70px; */
  right: 10px;
  width: 250px;
  z-index: 9999;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 1px;
  z-index: 1;
  top: 1px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style> 
