import { DeleteCompositeProductRepository } from '../../data/repository/delete_composite_producy_repository';
import { CompositeProduct } from '../model/compositeProduct';

export interface DeleteCompositeProductUseCase {
    (id: number): Promise<CompositeProduct>
}

const deleteCompositeProductUseCase = (repository: DeleteCompositeProductRepository): DeleteCompositeProductUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteCompositeProductUseCase;