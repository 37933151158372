<template>
  <v-text-field
    :dense="dense"
    :label="label"
    :rules="rules"
    v-model="data"
    :disabled="disabled"
    :filled="filled"
    readonly
    @click="menu = true"
    :single-line="singleLine"
  >
    <template v-slot:append>
      <v-menu
        class="white"
        ref="menu"
        v-model="menu"
        :close-on-click="true"
        :close-on-content-click="false"
        transition="scale-transition"
        left
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-icon
            tabindex="-1"
            v-bind="attrs"
            v-on="menu"
          >mdi-calendar</v-icon>
        </template>

        <div class="white pb-5">

          <v-date-picker
            elevation="0"
            v-model="picker"
            no-title
            @input="menu = false"
            :max="dayjs().add(1, 'year').format('YYYY-MM-DD')"
          ></v-date-picker>
          <v-divider></v-divider>
          <div class="mx-5 mt-5 white">
            <v-btn
              @click="picker = dayjs().format('YYYY-MM-DD'); menu = false"
              outlined
              block
            >hoje</v-btn>
          </div>
        </div>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import dayjs from "dayjs";
export default {
  data: () => ({
    data: null,
    picker: null,
    menu: false,
  }),
  mounted() {
    this.picker = this.value;
  },
  watch: {
    value() {
      this.data = dayjs(this.value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    picker() {
      this.data =
        this.value != ""
          ? dayjs(this.value, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "";
      this.$emit("input", this.picker);
    },
  },
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    'single-line': {
      type: Boolean,
      default: false,
    },
  },
};
</script>