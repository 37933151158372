import Handler from '@/core/domain/usecase/handler';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Category } from '../../domain/model/category';

export interface ChangeCategoryRepository {
  (id: number, data: Category): Promise<Category>
}

const changeCategoryRepository = (axios: AxiosInstance): ChangeCategoryRepository => async (id: number, data: Category) => {
  try {
    const response: AxiosResponse = await axios.put(`/category/${id}`,
      data
    )

    return response.data
  } catch (err: any | AxiosError) {
    if (err.isAxiosError) {
      throw new Handler().trataErro(err)
    } else {
      throw err
    }
  }
}

export default changeCategoryRepository

