import { CityPagination } from '@/module/city/domain/model/city_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { snackbar } from '@/core/controller/snackbar_controller';
import { Provider, providerDefault } from '../domain/model/provider';
import { ProviderPagination } from '../domain/model/provider_pagination';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';
import { FetchProviderUseCase } from '../domain/usecase/fetch_provider_usecase';
import { DeleteProviderUseCase } from '../domain/usecase/delete_provider_usecase';
import { ChangeProviderUseCase } from '../domain/usecase/change_provider_usecase';
import { CreateProviderUseCase } from '../domain/usecase/create_provider_usecase';
import { FetchCityUseCase } from '@/module/city/domain/usecase/fetch_city_usecase';

class ProviderController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public searchCity: string = ""
  public loading: boolean = false
  public providerPagination: ProviderPagination = defaultModelPagination()
  public cityPagination: CityPagination = defaultModelPagination()
  public optionsProvider: DataOptions = defaultPagination({})
  public optionsCity: DataOptions = defaultPagination({ itemsPerPage: 10, sortBy: ["name"] })
  public provider: Provider = providerDefault()

  public providersType = [
    {
      id: 1,
      name: "Pessoa juridica"
    },
    {
      id: 2,
      name: "Pessoa fisica"
    }
  ]

  constructor(
    private context: any,
    private fetchProviderUseCase: FetchProviderUseCase,
    private deleteProviderUseCase: DeleteProviderUseCase,
    private createProviderUseCase: CreateProviderUseCase,
    private changeProviderUseCase: ChangeProviderUseCase,
    private fetchCityUseCase: FetchCityUseCase,
  ) { }


  close() {
    this.provider = providerDefault()
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.providerPagination = await this.fetchProviderUseCase(this.optionsProvider, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  open() {
    this.paginateCity()
  }
  async paginateCity() {
    try {
      if (this.searchCity != null) {
        this.cityPagination = await this.fetchCityUseCase(this.optionsCity, this.searchCity)
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  watchSearchCity() {
    this.paginateCity()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        if (this.provider.id != 0) {
          await this.changeProviderUseCase(this.provider.id, this.provider)
          this.paginate()
          snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })


          this.dialog = false
          this.context.$refs.crud.resetValidation()
          return
        }

        await this.createProviderUseCase(this.provider)
        this.paginate()
        snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })

        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: Provider) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red', timeout: 5000 })
      if (confirm) {
        await this.deleteProviderUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Provider) {
    this.provider = { ...item }
    this.dialog = true
  }
}

export default ProviderController