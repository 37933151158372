import { CityPagination } from './../../city/domain/model/city_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchCityRepository {
    (pagination: Pagination): Promise<CityPagination>
}

const fetchCityRepository = (axios: AxiosInstance): FetchCityRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/city', {
            params: pagination
        })

        const cityPagination: CityPagination = response.data
        return cityPagination
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchCityRepository