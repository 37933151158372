import { ChangeAccessPlanRepository } from '../../data/repository/change_access_plan_repository';
import { AccessPlan } from '../model/access_plan';

export interface ChangeAccessPlanUseCase {
    (id: number, data: object): Promise<AccessPlan>
}

const changeAccessPlanUseCase = (repository: ChangeAccessPlanRepository): ChangeAccessPlanUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeAccessPlanUseCase;

