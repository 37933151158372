export const headers = [
  {
    text: 'Ativo',
    align: 'start',
    sortable: true,
    value: 'active',
  },
  {
    text: 'Descrição',
    align: 'start',
    sortable: true,
    value: 'description',
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '110px'
  }
]
