import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticStyle:{"z-index":"99999999"},attrs:{"persistent":"","width":"500"},model:{value:(_vm.alert.dialog),callback:function ($$v) {_vm.$set(_vm.alert, "dialog", $$v)},expression:"alert.dialog"}},[_c(VCard,{staticClass:"white--text",attrs:{"color":"primary"}},[_c(VCardTitle,[_c(VSpacer),_vm._v(" Aviso! "),_c(VSpacer)],1),_c(VCardText,{staticClass:"white--text text-center"},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c(VCardText,[_c('lottie',{staticClass:"mt-10",attrs:{"options":_vm.alert.lottieAnimation,"height":300,"width":300}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"large":"","block":"","outlined":"","color":"white"},on:{"click":function($event){_vm.alert.dialog = false}}},[_vm._v(" ok ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }