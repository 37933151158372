import { CreateCompanyRepository } from './../../data/repository/create_company_repository';
import { Company } from '../model/company';

export interface CreateCompanyUseCase {
    (data: object): Promise<Company>
}

const createCompanyUseCase = (repository: CreateCompanyRepository): CreateCompanyUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createCompanyUseCase;

