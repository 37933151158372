import { Account } from './../../../../core/domain/model/account';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchPreRegistrationRepository {
    (): Promise<Array<Account>>
}

const fetchPreRegistrationRepository = (axios: AxiosInstance): FetchPreRegistrationRepository => async () => {
    try {
        const response: AxiosResponse = await axios.get('/pre-registration/account')

        const users: Array<Account> = response.data
        return users
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchPreRegistrationRepository