import { AccessPlan } from '../../domain/model/access_plan';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteAccessPlanRepository {
    (id: number): Promise<AccessPlan>
}

const deleteAccessPlanRepository = (axios: AxiosInstance): DeleteAccessPlanRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/access-plan/${id}`)

        const accessPlan: AccessPlan = response.data
        return accessPlan
    } catch (err: any | AxiosError) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteAccessPlanRepository